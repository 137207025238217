$container-width: 22.5rem;
.Mobile{
    .preview-head{
        top: 1.25rem;
        padding: 0 0.9375rem;;
        font-size: 1rem;
        line-height: 1.1875rem;
        .btn-exit{
            height: 1.1875rem;
            padding: 0;
            font-size: 1rem;
            right: 0.9375rem;
            .icon{
                font-size: .8125rem;
            }
        }
    }
    .Challenge{
        padding-top: 7.8125rem;
        .quest-title{
            padding-top: 3.75rem;
            .title{
                width: 100%;
                height: 3.125rem;
                padding: 0 .9375rem;
                font-size: 1.125rem;
                span{
                    font-size: 1.0625rem;
                }
                p{
                    width: 18.75rem;
                    margin-left: 1.25rem;
                    line-height: 1.3125rem;
                    font-size: 1.125rem;
                    -webkit-line-clamp: 1;
                }
            }
        }
        .content{
            width: $container-width;
            height: calc(100vh - 5rem - 7.8125rem);
            margin: 0 auto;
            .challenge-title{
                padding: 1.25rem 1.125rem .3125rem;
                margin-bottom: .9375rem;
                font-size: 1rem;
                line-height: 1.1875rem;
                border-width: .0625rem;
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
                strong{
                    margin-left: .8125rem;
                    line-height: 1.3125rem;
                    font-size: 1.125rem;
                    
                }
            }
            .inner-title{
                font-size: .875rem;
                line-height: 1rem;
            }
        }
        .h-a{
            height: auto;
        }
        .progress{
            bottom: 3.75rem;
            .ant-progress-bg{
                height: .3125rem !important;
            }
        }
    }
.preivew-correct{
    font-size: 0.875rem;
    line-height: 1.1875rem;
}
    .CustomInput{
        padding: 0 1.125rem;
        .custom-input{
            padding: .5rem;
            font-size: .875rem;
            border-width: .0625rem;
        }
        .bd{
            border-radius: .4375rem;
        }
        .CustomInput-content{
            margin-top: 3.4375rem;
            .correct-content{
                margin-top: .625rem;
                margin-left: .625rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
            .preivew-correct{
                margin-right: .5rem;
            }
        }
    }
    
    .CustomRadio{
        padding: 0 1.125rem;
        .ant-radio-group{
            margin-top: 3.4375rem;
        }
        .CustomRadio{
            padding: 0;
        }
        .ant-radio-wrapper{
            margin-bottom: 1.875rem;
            font-size: .875rem;
            line-height: 1rem;
            .ant-radio{
                margin-right: .625rem;
            }
            .ant-radio-inner{
                width: 1.5rem;
                height: 1.5rem;
            }
            .ant-radio-inner::after{
                width: .375rem;
                height: .625rem;
                border-width: .125rem;
            }
        }
        .preivew-correct{
            margin-left: .625rem;
        }
    }
    
    .CustomCheckbox{
        padding: 0 1.125rem;
        .custom-checkbox{
            margin-top: 3.4375rem;
        }
        .ant-checkbox-wrapper{
            margin-bottom: 1.875rem;
            font-size: .875rem;
            line-height: normal;
            .ant-checkbox{
                margin-right: .625rem;
            }
            .ant-checkbox, .ant-checkbox-inner{
                width: 1.5rem;
                height: 1.5rem;
                margin-top: -0.125rem;
            }
            .ant-checkbox-inner::after{
                width: .375rem;
                height: .625rem;
                left: 30%;
                border-width: .125rem;
            }
        }
        .preivew-correct{
            margin-left: .625rem;
        }
    }
    
    .CustomCode{
        width: 100%;
        gap: .625rem;
        flex-direction: column;
        padding-bottom: 5rem;
        .code-left, .code-out{
            width: 100%;
        }
        .code-left{
            border-radius: .4375rem;
        }
        .code-desc{
            padding-left: 1.125rem;
            .markdown-body{
                font-size: 1rem;
                padding-bottom: 1.875rem;
            }
        }
        .code-desc, .code-content{
            height: auto;
        }
        .code-out{
            height: 100%;
            padding: 0;
            padding-bottom: .625rem;
            position: relative;
            .code-menu{
                width: 100%;
                position: absolute;
                top: -2rem;
                left: 0;
                display: flex;
                justify-content: space-between;
            }
        }
        .out-inner{
            flex: none;
            height: 15rem;
            margin-bottom: .625rem;
            padding-top: 3.4375rem;
            border-radius: .4375rem;
        }
        .out-content{
            height: 12.5rem;
            border-radius: .4375rem;
        }
        .CustomConsole{
            .tab ul{
                height: 1.875rem;
                padding: .5rem .75rem 0;
                font-size: .75rem;
                border-width: .0625rem;
                li{
                    margin-right: 1.5625rem;
                    padding-bottom: 0;
                }
                .active{
                    border-width: .0625rem;
                }
            }
            .console, .case{
                height: 8.125rem;
                margin: .375rem .5rem;
                textarea{
                    border-radius: .4375rem;
                }
            }
        }
        .btns{
            padding: 0 .5rem 0 1.375rem;
            display: flex;
            justify-content: space-between;
            .ant-dropdown-trigger{
                line-height: normal;
            }
            .ant-space-item{
                font-size: .625rem;
            }
            button{
                height: 1.375rem;
                padding: 0 .8125rem;
                border-width: .0625rem;
                border-radius: .125rem;
                font-size: .625rem;
                .anticon-caret-right{
                    font-size: .75rem;
                }
            }
        }
    }
    
    .example-dropdown{
        strong{
            font-size: .75rem;
            line-height: 1.0625rem;
        }
        .example{
            font-size: .75rem;
            line-height: 1.0625rem;
        }
    }
}