.Question{
    width: 1440px;
    margin: 145px auto 70px;
    h1{
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
    }
    .content{
        display: flex;
        justify-content: space-between;
    }
    .content-right{
        width: 860px;
    }
    .title{
        font-size: 20px;
        font-weight: 900;
        color: #000000;
        // line-height: 29px;
        display: flex;
        align-items: center;
        span{
            min-width: 40px;
            height: 20px;
            margin-left: 24px;
            display: inline-block;
            text-align: center;
            color: #8F5A35;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            border-radius: 26px;
            background-color: rgba(143,90,53,0.1);
            border: 1px solid #8F5A35;
        }
    }
}
// content-left
.quest-title{
    .img{
        width: 500px;
        height: 500px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        .img-mask{
            width: 100%;
            height: 115px;
            padding: 0 28px;
            position: absolute;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
            display: flex;
            align-items: center;
            .newline-omitted{
                color: #fff;
                font-size: 31px;
                font-weight: 600;
                -webkit-line-clamp: 2;
            }
        }
    }
}
.quest-challenger{
    width: 515px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // 选择器
    .selector{
        width: 306px;
        border: 1px solid #DCDFF1;
        border-radius: 20px;
        .ant-segmented-thumb{
            border-radius: 20px !important;
        }
        .ant-segmented-item{
            width: 100px;
            border-radius: 20px !important;
            .ant-segmented-item-label{
                height: 34px;
                line-height: 34px;
                font-weight: 500;
            }
        }
    }
    // 标题
    .label{
        width: 100%;
        margin-top: 20px;
        padding: 10px 0;
        font-size: 16px;
        line-height: 18px;
        color: #C0C0C0;
        border-bottom: 1px solid #F2F3F7;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .list {
        width: 100%;
        min-height: 110px;
        max-height: 665px;
        overflow-y: auto;
    }
    .flex-c{
        display: flex;
        align-items: center;
        justify-content: center;
        .nodata{
            color: #C0C0C0;

        }
    }
    ul{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        li{
            height: 55px;
            padding-left: 10px;
            padding-right: 33px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background-color 0.2s;
            &:hover{
                background-color: #FCFCFC;
            }
            .info{
                display: flex;
                align-items: center;
                gap: 12px;
                .no{
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #A9A9A9;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .avatar{
                    border-radius: 50%;
                    overflow: hidden;
                    cursor: pointer;
                }
                .addr:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .res{
                text-align: right;
                .c9{
                    color: #999999;
                }
            }
        }
        .my-rank{
            padding-left: 0;
            background-color: #FDF9F0;
            border-left: 2px solid #8F5A35;
            // background: linear-gradient(90deg, #8F5A35 0%, rgba(143,90,53,0) 100%);
            .info{
                height: 100%;
                padding-left: 8px;
                .no{
                    color: #190B06;
                }
            }
            .res{
                .score, .time{
                    color: #8F5A35;
                }
                .c9{
                    color: rgba($color: #8F5A35, $alpha: 0.5);
                }
            }
        }
        .btn-more{
            width: 30px;
            height: 30px;
        }
    }
}

// content-right
.quest-info{
    .li{
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:last-child{
            margin: 0;
        }
    }
    .info-content{
        width: 710px;
        min-height: 47px;
        padding-bottom: 30px;
        border-bottom: 1px solid #F5F5F5;
        display: flex;
        align-items: center;
        img{
            width: 21px;
            height: 21px;
            margin-right: 5px;
        }
    }
    .avatar{
        img{
            margin-right: 0;
        }
    }
    .img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .mt13{
        margin-top: 13px;
    }
    .name{
        width: 400px;
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;  
        text-overflow:ellipsis; 
        overflow:hidden;
    }
    .overflow{
        .markdown-body{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 9;
        }
    }
    .desc{
        p{
            font-size: 16px;
            color: #403A35;
            line-height: 21px;
        }
    }
    .reward{
        display: flex;
        gap: 14px;
        .reward-item{
            padding: 6px 11px;
            background-color: #FBF8F6;
            border-radius: 3px;
            color: #8F5A35;
            font-weight: 600;
        }
    }
    .ant-rate li{
        margin-bottom: 0;
        .anticon-star{
            font-size: 16px;
        }
    }
    .time{
        font-size: 16px;
        color: #403A35;
        line-height: 21px;
    }
    .recommend{
        width: 710px;
        height: 220px;
        border: 1px solid #F5F5F5;
        border-radius: 10px;
        .markdown-body{
            padding: 12px 16px;
        }
    }
    .submit{
        width: 100%;
        height: 60px;
        border: none;
        box-shadow: none;
        background-color: #8F5A35;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}
.info-top{
    min-height: calc(500px - 13px - 40px + 45px);
    margin-top: 13px;
    overflow: hidden;
    margin-bottom: 40px;
}
.info-bottom{
    li{
        margin-bottom: 35px;
    }
}