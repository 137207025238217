// 重复绑定弹窗
.rebind-modal{
    .title{
        margin-bottom: 40px;
        font-size: 21px;
    }
    .text{
        margin-bottom: 18px;
    }
    .btns{
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        button{
            width: 48%;
            height: 40px;
            border-radius: 8px;
            background-color: #f6f8fb;
            border: none;
            box-shadow: none;
            color: #000;
        }
    }
}

// 绑定邮箱弹窗
.email-modal .ant-modal {
    .ant-modal-content{
        padding: 40px;
        padding-bottom: 50px;
        border-radius: 40px;
    }
    .ant-modal-title{
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 21px;
    }
    .ant-modal-body{
        .text{
            font-size: 16px;
        }
        input{
            width: 100%;
            height: 57px;
            padding: 0 23px;
            margin-top: 13px;
            border-radius: 9px;
        }
        button{
            width: 100%;
            height: 54px;
            margin-top: 40px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

// 输入验证码弹窗
.email-code-modal .ant-modal {
    .ant-modal-content{
        padding: 40px;
        padding-bottom: 50px;
        border-radius: 40px;
    }
    .ant-modal-title{
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 21px;
    }
    .ant-modal-body{
        .inner{
            height: 60px;
            margin: 20px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            div{
                width: 60px;
                height: 100%;
                border: 1px solid #E2E2E2;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 32px;
            }
            .active{
                border-color: #8F5A35;
            }
            .input{
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0;
                .ant-input-number-input-wrap, input{
                    width: 100%;
                    height: 100%;
                }
                &::-webkit-inner-spin-button {
                    display: none;
                }
            }
        }
        .time .ant-statistic-content{
            text-align: center;
            font-size: 14px;
        }
        button{
            width: 100%;
            height: 54px;
            margin-top: 40px;
            font-size: 16px;
        }
        #hover-btn-full:disabled{
            background-color: #F6F8FB;
            border: none;
            color: #000;
        }
    }
}