$container-width: 21.875rem;

.Mobile{
    .User{
        width: $container-width;
        padding-top: 6.25rem;
    }
    .User-info{
        height: auto;
        padding-bottom: 3.4375rem;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .btn{
            width: 100%;
            height: 2rem;
            border-radius: .5rem;
            font-size: .75rem;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #fff;
            color: #8F5A35;
            border: .0625rem solid #8F5A35;
            .anticon-edit{
                font-size: .75rem;
            }
        }
        .img{
            width: 6rem;
            height: 6rem;
            margin: 0;
            margin-bottom: .625rem;
        }
        .info{
            text-align: center;
            .name{
                font-size: 1.625rem;
                line-height: 1.625rem;
            }
            .address{
                margin-top: .3125rem;
                font-size: .9375rem;
                line-height: 1.0625rem;
            }
            .social{
                margin-top: .3125rem;
                justify-content: center;
                gap: .625rem;
                .social-item{
                    height: 1.625rem;
                    padding: .4375rem 1rem .4375rem .625rem;
                    font-size: .75rem;
                }
                .icon{
                    width: 1rem;
                    height: 1rem;
                    margin-right: .625rem;
                    margin-bottom: 0;
                    background-color: #00000000;
                }
            }
            .desc{
                max-width: 16.875rem;
                margin: .625rem auto 0;
                font-size: .75rem;
                line-height: 1.125rem;
            }
        }
    }
    .navbar{
        min-height: 3.9375rem;
        margin-top: 3.125rem;
        .fixed{
            width: 100%;
            padding-top: 3.75rem;
            padding-bottom: .625rem;
            position: fixed;
            top: 0;
            z-index: 888;
            background-color: #fff;
        }
    }
    .User-list{
        margin-top: 0;
        .challenge{
            margin-bottom: .9375rem;
            li{
                margin-right: 2.5rem;
                font-size: 1.125rem;
                &:last-child{
                    margin-right: 0;
                }
            }
            .active{
                color: #000;
                cursor: auto;
            }
        }
        .status{
            li{
                margin-right: 2.1875rem;
                font-size: .875rem;
                line-height: 1.25rem;
            }
        }
    }
    .User-content{
        padding-top: 1.6875rem;
        padding-bottom: 3.125rem;
        gap: .625rem;
        .nodata{
            width: 100%;
            height: 15.625rem;
            p{
                margin-bottom: .875rem;
                font-size: .75rem;
                line-height: 1.0625rem;
            }
            .nodata-btn{
                height: 2.75rem;
                padding: .8125rem 4rem;
                font-size: .75rem;
                line-height: 1.125rem;
            }
        }
    }
}