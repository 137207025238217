
.ModalZkCard{
    .ant-modal-content{
        padding: 30px !important;
        padding-bottom: 140px !important;
        background-color: #FBF8F6 !important;
        border-radius: 20px !important;
        position: relative;
    }
    .zkCard-content{
        // height: 500px;
        display: flex;
        gap: 23px;
    }
    .zkCard-btns{
        width: 100%;
        height: 110px;
        background-color: #FEFEFE;
        border-end-end-radius: 20px;
        border-end-start-radius: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 23px;
        button{
            width: 50px;
            height: 50px;
            background-color: rgba($color: #2F3745, $alpha: 0.05);
            border: none;
            border-radius: 50%;
            cursor: pointer;
        }
        .full{
            padding: 0;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .zkCard-item{
        width: 100%;
        padding: 22px;
        border-radius: 18px;
        background-color: #fff;
    }
    .card{
        width: 100%;
        height: 227px;
        margin-bottom: 15px;
        border-radius: 16px;
        position: relative;
        // background: url("../../images/img/zk-card2.png");
        // background-size: cover;
        overflow: hidden;
        .card-inner{
            width: 100%;
            height: calc(100% - 63.62%);
            padding: 0 20px;
            position: absolute;
            display: flex;
            align-items: center;
            left: 0;
            bottom: 0;
            color: #fff;
            font-size: 23px;
            font-weight: 600;
            line-height: 23px;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
            p{
                -webkit-line-clamp: 2;
            }
        }
        .card-bg{
            width: 100%;
            margin-top: calc((227px - 100%) / 2);
        }
        .label{
            font-size: 12px;
        }
        .desc{
            font-weight: 500;
        }
    }
    .list{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .item{
        .label{
            margin-bottom: 15px;
            color: #8E8E8E;
            font-size: 12px;
        }
        .li-label{
            color: #5C5B5A;
        }
        .li-desc{
            font-weight: 500;
        }
        .desc{
            padding: 10px 20px;
            background-color: #FBFBFB;
            border-radius: 10px;
            font-weight: 500;
            border: 1px solid #E9E9E9;
        }
        ul{
            border-radius: 10px;
            background-color: #FBFBFB;
            border: 1px solid #E9E9E9;
        }
        li{
            padding: 11px 19px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F8F4F3;
            &:last-child{
                border-bottom: none;
            }
        }
    }

    .params-list{
        border-radius: 10px;
        background-color: #FBFBFB;
    }
    .params{
        padding: 15px 20px;
        border-bottom: 1px solid #F8F4F3;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &:last-child{
            border-bottom: none;
        }
        .label{
            color: #5C5B5A;
        }
        .desc{
            font-weight: 500;
        }
        .status{
            padding: 2px 16px;
            color: #8F5A35;
            font-weight: 500;
            line-height: 20px;
            background-color: rgba($color: #8F5A35, $alpha: 0.1);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .row{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.ModalZkCard-mobile{
    .ant-modal-content{
        max-height: 80vh;
        overflow-y: scroll;
        // padding: 1.4375rem !important;
        padding: 0 !important;
        padding-top: 1.4375rem !important;
    }
    .zkCard-content{
        flex-direction: column;
        // margin: 0 1.4375rem;
        padding: 0 1.4375rem;
    }
    .card{
        height: 11rem;
        margin-bottom: .625rem;
        .card-inner{
            font-size: 1.0625rem;
        }
        .label{
            font-size: .5625rem;
        }
        .desc{
            font-size: .6875rem;
        }
    }
    .zkCard-item{
        padding: 1.0625rem;
    }
    .params-list{
        border-radius: .4813rem;
    }
    .params{
        padding: .625rem .9375rem;
        gap: .3125rem;
        .status{
            padding: .125rem .75rem;
            line-height: 1.0625rem;
            font-size: .75rem;
            border-radius: .375rem;
        }
        .label, .desc{
            font-size: .75rem;
        }
    }
    .zkCard-btns{
        // width: calc(100vw - 16px);
        position: sticky;
        bottom: 0;
        left: -16px;
        // bottom: 10vh;
    }
}