.Collection{
    width: 1440px;
    min-height: calc(100vh - 82px);
    margin: 82px auto 0;
    .question-content{
        width: 100%;
        height: 100%;
        display: flex;
        .question-left{
            width: 990px;
            padding-left: 85px;
            padding-right: 70px;
            border-right: 1px solid #E9E9E9;
        }
        .question-right{
            width: 450px;
            padding-top: 62px;
            padding-left: 37px;
            padding-right: 55px;
            position: fixed;
            left: calc((100% - 1440px) / 2 + 990px);
            .ant-tooltip{
                max-width: 100%;
            }
            .progress{
                margin-top: 30px;
                .progress-tips{
                    font-size: 12px;
                    color: #999999;
                    span{
                        margin-right: 5px;
                    }
                }
                .progress-info{
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                }
            }
            .nft{
                width: 358px;
                height: 358px;
                padding-top: 8px;
                border-radius: 13px;
                border: 1px solid #8F5A35;
                display: flex;
                flex-direction: column;
                align-items: center;
                .img{
                    width: 340px;
                    height: 340px;
                    border-radius: 11px;
                    overflow: hidden;
                    background-color: rgba($color: #8F5A35, $alpha: 5%);
                    position: relative;
                    .badge{
                        position: absolute;
                        right: 7px;
                        top: 7px;
                        img{
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
                .status{
                    width: 130px;
                    height: 30px;
                    margin-top: 10px;
                    text-align: center;
                    color: #8F5A35;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 30px;
                    border-radius: 15px;
                    background-color: rgba($color: #8F5A35, $alpha: 0.1);
                }
            }
            .btn-normal{
                background-color: #8F5A35;
                color: #fff;
                font-size: 16px;
                transition: background-color 0.3s;
                &:hover{
                    color: #fff;
                    background-color: #B47243;
                }
            }
            .btn-disable{
                background-color: #F0F0F0;
                color: #000;
            }
            .btn-airpost{
                background-color: #ffffff00;
                border: 1px solid #8F5A35;
                color: #8F5A35;
            }
            button{
                width: 100%;
                height: 50px;
                font-size: 16px;
                border-radius: 10px;
                border: none;
                box-shadow: none;
            }
            .airpostTips{
                width: 100%;
                min-height: 30px;
                padding: 7px 0;
                margin-top: 10px;
                text-align: center;
                line-height: 17px;
                font-size: 12px;
                color: #44B573;
                font-weight: 500;
                border-radius: 10px;
                background: rgba(69,182,116,0.1);
            }
            .claimedTips{
                margin-top: 16px;
                text-align: center;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    h1{
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
    }
    .content{
        display: flex;
        justify-content: space-between;
    }
    .content-right{
        width: 860px;
    }
    .title{
        font-size: 20px;
        font-weight: 900;
        color: #000000;
        // line-height: 29px;
        display: flex;
        align-items: center;
        span{
            min-width: 40px;
            height: 20px;
            margin-left: 24px;
            display: inline-block;
            text-align: center;
            color: #8F5A35;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            border-radius: 26px;
            background-color: rgba(143,90,53,0.1);
            border: 1px solid #8F5A35;
        }
    }
    // content-left
    .quest-challenger{
        width: 100%;
        margin-top: 1.875rem;
        // padding-bottom: 2.5rem;
        // border-bottom: .0625rem solid #F5F5F5;
        // 选择器
        .selector{
            width: 19.125rem;
            border-radius: 1.25rem;
            .ant-segmented-thumb{
                border-radius: 1.25rem !important;
            }
            .ant-segmented-item{
                width: 6.25rem;
                border-radius: 1.25rem !important;
                .ant-segmented-item-label{
                    height: 2.125rem;
                    line-height: 2.125rem;
                }
            }
        }
        // 标题
        .label{
            margin-top: 1.25rem;
            padding: .625rem 0;
            font-size: .875rem;
            line-height: 1.125rem;
        }
        .list {
            max-height: calc(100vh - 82px - 62px - 305px - 32px - 50px - 30px - 23px - 20px - 100px - 100px);
        }
        ul{
            margin-top: .625rem;
            gap: .3125rem;
            li{
                height: 3.4375rem;
                padding-left: 0;
                padding-right: 0;
                .info{
                    padding-left: .5rem;
                    gap: .5rem;
                    .no{
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                    .addr{
                        font-size: .8125rem;
                    }
                }
                .res{
                    .score{
                        font-size: .875rem;
                    }
                    .c9{
                        font-size: .75rem;
                    }
                }
            }
            .my-rank{
                padding-left: 0;
                border-left: .125rem solid #8F5A35;
                .info{
                    padding-left: .5rem;
                }
            }
        }
    }
    
    // content-right
    .collection-info{
        padding-top: 64px;
        .li{
            margin-bottom: 30px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            &:last-child{
                margin: 0;
            }
        }
        .info-content{
            width: 710px;
            min-height: 47px;
            padding-bottom: 30px;
            border-bottom: 1px solid #F5F5F5;
            display: flex;
            align-items: center;
            img{
                width: 21px;
                height: 21px;
                margin-right: 5px;
            }
        }
        .avatar{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                margin-right: 0;
            }
        }
        .mt13{
            margin-top: 13px;
        }
        .name{
            width: 400px;
            margin-left: 20px;
            font-size: 20px;
            font-weight: 600;
            white-space: nowrap;  
            text-overflow:ellipsis; 
            overflow:hidden;
        }
        .overflow{
            .markdown-body{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 9;
            }
        }
        .desc{
            p{
                font-size: 16px;
                color: #403A35;
                line-height: 21px;
            }
        }
        .reward{
            font-size: 16px;
            color: #403A35;
            font-weight: 600;
            line-height: 21px;
        }
        .ant-rate li{
            margin-bottom: 0;
            .anticon-star{
                font-size: 16px;
            }
        }
        .time{
            font-size: 16px;
            color: #403A35;
            line-height: 21px;
        }
        .recommend{
            width: 710px;
            height: 220px;
            border: 1px solid #F5F5F5;
            border-radius: 10px;
            .markdown-body{
                padding: 12px 16px;
            }
        }
        .submit{
            width: 100%;
            height: 60px;
            border: none;
            box-shadow: none;
            background-color: #8F5A35;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
        .challenges{
            margin-top: 20px;
            .ChallengeItem{
                margin-bottom: 20px;
            }
        }
        .collection-info-top{
            overflow: hidden;
            .quest-creator{
                margin-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .creator-info{
                    display: flex;
                    align-items: center;
                }
                .btn-share{
                    height: 44px;
                    padding: 0 30px;
                    border: none;
                    box-shadow: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    background-color: #000000;
                }
            }
        }
    }
    .comming{
        width: 700px;
        height: 45px;
        padding: 0;
        display: block;
        border: 1px solid rgba(0,0,0,0.5);
        border-radius: 10px;
        background: rgba(0,0,0,0.05);
        text-align: center;
        line-height: 45px;
        font-weight: 500;
    }
}    


.Collection-mobile{
    width: 100%;
    margin: 3.75rem auto 0;
    padding-left: 25px;
    padding-right: 18px;
    .question-content{
        flex-direction: column;
        .question-left{
            width: 100%;
            padding: 0;
            border: none;
        }
        .question-right{
            width: 100%;
            padding: 0;
            position: static;
            .progress{
                margin-top: 1.875rem;
                .progress-tips{
                    font-size: .75rem;
                    span{
                        margin-right: .3125rem;
                    }
                }
                .progress-info{
                    margin-top: .3125rem;
                }
            }
            .nft{
                width: 100%;
                height: 25.625rem;
                padding: .5625rem;
                border-radius: .75rem;
                .img{
                    width: 100%;
                    height: 100%;
                    border-radius: .5rem;
                    .badge{
                        right: .625rem;
                        top: .625rem;
                        img{
                            width: 2.5rem;
                            height: 2.5rem;
                        }
                    }
                }
            }
            .btn-normal{
                font-size: 1rem;
            }
            button{
                height: 3.125rem;
                font-size: 1rem;
                border-radius: .625rem;
            }
            .airpostTips{
                width: 100%;
                height: 1.875rem;
                margin-top: .625rem;
                line-height: 1.875rem;
                font-size: .75rem;
                border-radius: .625rem;
            }
            .claimedTips{
                margin-top: 1rem;
                font-size: .75rem;
                line-height: 1.0625rem;
            }
        }
    }
    h1{
        margin-bottom: 1.25rem;
        font-size: 2rem;
        line-height: 2.8125rem;
    }
    .newline-omitted{
        -webkit-line-clamp: 3;
    }
    .content{
        display: flex;
        justify-content: space-between;
    }
    .content-right{
        width: 100%;
    }
    .title{
        font-size: 1.125rem;
        span{
            min-width: 2.5rem;
            height: 1.25rem;
            margin-left: 1.5rem;
            font-size: .75rem;
            line-height: 1.25rem;
            border-radius: 1.625rem;
        }
    }
    // content-left
    .quest-challenger{
    //     width: 100%;
    //     margin-top: 1.875rem;
    //     .list ul{
    //         max-height: 100%;
    //         margin-top: 1.25rem;
    //         gap: 1.1875rem;
    //         li{
    //             width: 3.3125rem;
    //             height: 3.3125rem;
    //         }
    //         .arrow{
    //             font-size: .75rem;
    //         }
    //     }
        .list {
            max-height: none;
        }
    }
    
    // content-right
    .collection-info{
        padding-top: 1.375rem;
        .li{
            margin-bottom: .875rem;
            flex-direction: column;
        }
        .info-content{
            width: 100%;
            min-height: 1.3125rem;
            padding-top: .625rem;
            padding-bottom: 1.125rem;
            img{
                width: 1.3125rem;
                height: 1.3125rem;
                margin-right: .3125rem;
            }
        }
        .avatar{
            width: 3.125rem;
            height: 3.125rem;
        }
        .mt13{
            margin-top: 13px;
        }
        .name{
            width: auto;
            max-width: 11.25rem;
            margin-left: 1.25rem;
            font-size: 1.25rem;
        }
        .desc{
            p{
                font-size: 1rem;
                line-height: 1.3125rem;
            }
        }
        .reward{
            font-size: 1rem;
            line-height: 1.3125rem;
        }
        .ant-rate li{
            margin-bottom: 0;
            .anticon-star{
                font-size: 1rem;
            }
        }
        .time{
            font-size: 0.8rem;
            line-height: 1.3125rem;
        }
        .challenges{
            margin-top: 1.25rem;
            .ChallengeItem{
                margin-bottom: 1.25rem;
            }
        }
        .collection-info-top{
            .quest-creator{
                margin-bottom: 1.25rem;
                .btn-share{
                    height: 2.5rem;
                    padding: 0 1.625rem;
                    font-size: 1rem;
                }
            }
        }
    }
    .comming{
        width: 100%;
        margin-bottom: 1.25rem;
        // height: 45px;
        // padding: 0;
        // display: block;
        // border: 1px solid rgba(0,0,0,0.5);
        // border-radius: 10px;
        // background: rgba(0,0,0,0.05);
        // text-align: center;
        // line-height: 45px;
        // font-weight: 500;
    }
}