$container-width: 90vw;
.Mobile{
    .Cert{
        width: 100vw;
        margin: 0;
        padding: 5.1875rem 5vw 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        min-height: 40rem;
        max-height: 100vh;
        .provide{
            width: 162px;
            right: calc((100% - 162px) / 2);
            bottom: 5px;
            font-size: 10px;
            strong{
                padding: 0 5px 3px;
                font-size: 20px;
                line-height: 14px;
            }
        }
    }
    .header-line{
        height: .0625rem;
        top: 3.75rem;
    }
    .none{
        display: none;
    }
    .Cert-sidbar{
        width: 100%;
        min-height: calc(100vh - 5.1875rem);
        padding-right: 0rem;
        padding-top: 0rem;
        .search{
            .search-title{
                margin-bottom: .9375rem;
                font-size: 1.375rem;
                line-height: 1.625rem;
            }
            .search-inner{
                height: 2.9375rem;
                margin-bottom: 1.875rem;
                padding: .8125rem 1.125rem .8125rem 1rem;
                border-width: .0625rem;
                border-radius: .5625rem;
                .ant-input-affix-wrapper{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .icon{
                margin-right: .625rem;
                font-size: 1.125rem;
            }
            input{
                font-size: 1.125rem;
            }
            .anticon-close{
                font-size: 1rem;
            }
        }
        .user{
            .avatar > .img{
                width: 5.625rem;
                height: 5.625rem;
            }
            .user-info{
                margin-left: 1.125rem;
                .name{
                    max-width: 12.5rem;
                    margin-bottom: .625rem;
                    font-size: 1.125rem;
                    line-height: 1.375rem;
                }
                .address{
                    max-width: 12.5rem;
                    font-size: .8125rem;
                    line-height: .9375rem;
                    .anticon-copy{
                        margin-left: 1.875rem;
                    }
                }
            }
            .social{
                margin-top: 1.25rem;
                .items{
                    width: 17.5rem;
                    .item{
                        width: 2.75rem;
                        height: 2.75rem;
                        &:hover{
                            box-shadow: 0 .125rem .1875rem .0625rem rgba($color: #000000, $alpha: 0.1);
                        }
                        img{
                            width: 1.875rem;
                        }
                    }
                }
                .ant-divider-vertical{
                    height: 1.375rem;
                }
                .share{
                    width: 1.4375rem;
                    height: 1.4375rem;
                    margin-left: 1.875rem;
                    font-size: 1rem;
                }
            }
        }
        .line{
            margin: 1.5625rem auto 1.5625rem;
        }
        .nfts{
            width: 100%;
            height: calc(100vh - (1.5625rem * 2) - 5.625rem - 1.25rem - 2.75rem - 1.625rem - 0.9375rem - 2.9375rem - 1.875rem - 5.1875rem - 1rem);
            .add{
                margin-bottom: .875rem;
                font-size: 1.125rem;
                font-weight: 700;
                p{
                    font-size: 1rem;
                    line-height: 1.625rem;
                }
                button{
                    width: 1.625rem;
                    height: 1.625rem;
                    font-size: 1rem;
                }
            }
            ul{
                max-height: 100%;
                li{
                    height: 2.875rem;
                    padding: .3125rem;
                    margin-bottom: .3125rem;
                    border-radius: .3125rem;
                    .poa{
                        right: 1.5625rem;
                    }
                    p{
                        font-size: .8125rem;
                    }
                    .li-content{
                        max-width: calc(100% - 8.125rem);
                    }
                    .img{
                        width: 2.3125rem;
                        height: 2.3125rem;
                        margin-right: .875rem; 
                        .badge{
                            width: .9375rem;
                            height: .9375rem;
                            right: -0.5rem;
                            top: -0.5rem;
                        }
                    }
                }
            }
        }
    }
    .Cert-content{
        width: 100%;
        padding-top: 2.8125rem;
        padding-bottom: 2.8125rem;
        border: none;
        position: relative;
        .content-header-mobile{
            width: 100%;
            position: absolute;
            align-items: center;
            top: 0;
        }
        .back{
            width: 1.875rem;
            height: 1.875rem;
            border-radius: .125rem;
            background-color: #EDF1F4;

            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
        }
        .reload{
            height: 2.5rem;
            padding: 0 1rem 0 .9375rem;
            font-size: .75rem;
            img{
                width: .8125rem;
                height: .8125rem;
                margin-right: .375rem;
            }
        }
        ul{
            padding-left: 0;
            padding-right: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            li{
                min-width: 4.6875rem;
                width: auto;
                margin-right: .875rem;
            }
        }
        .nfts{
            padding: 0;
            padding-top: 1.375rem;
            position: relative;
            overflow: scroll;
            .nodata{
                p{
                    font-size: .75rem;
                    font-weight: 900;
                    color: #C1C1C1;
                    line-height: 1.125rem;
                    display: block;
                }
            }
            .scroll{
                width: 100%;
                gap: .875rem;
                clip-path: inset(-0.625rem);
            }
            .flex{
                padding-left: .5625rem;
                gap: .625rem;
                top: .75rem;
                left: 0;
            }
            .nft-detail{
                width: 10.4375rem;
                border-radius: .375rem;
                &:hover{
                    .nft-info .bottom{
                        bottom: 0;
                    }
                }
                .badge{
                    width: 1.4375rem;
                    height: 1.4375rem;
                }
                .badge-chain{
                    left: .5625rem;
                    top: .5625rem;
                }
                .badge-opensea{
                    left: 2.8125rem;
                    top: .5625rem;
                }
                .img{
                    height: 10rem;
                }
                .nft-info{
                    width: 100%;
                    height: 3.75rem;
                    .nft-title{
                        height: 1.375rem;
                        padding: .5625rem .8125rem 0 .4375rem;
                        font-size: .75rem;
                        line-height: .8125rem;
                    }
                    .bottom{
                        height: 1.25rem;
                        bottom: -1.25rem;
                        p{
                            width: calc(100% - 1.875rem);
                            text-align: center;
                            font-size: .625rem;
                        }
                        .more{
                            width: 1.875rem;
                            .icon{
                                font-size: .75rem;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
    }
    .Cert-addsbt{
        position: relative;
        .back{
            margin-bottom: 1.4375rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon{
                width: 1.875rem;
                height: 1.875rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #EDF1F4;
                border-radius: .125rem;
            }
        }
        .title{
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.625rem;
        }
        .search{
            margin-top: .9375rem;
            .confirm{
                min-width: 5rem;
                height: 1.875rem;
                padding: 0;
                border-radius: .9375rem;
                font-size: .875rem;
                color: #9E9E9E;
                line-height: 1.625rem;
                border: .125rem solid #BABABA;
                position: absolute;
                right: 0;
                top: 0;
            }
            .inner{
                width: 100%;
                height: 2.1875rem;
                border: .125rem solid #BABABA;
                border-radius: 1.125rem;
                display: flex;
                align-items: center;
                .ant-select-selection-item{
                    text-overflow: clip;
                }
                .input{
                    width: 14.375rem;
                    height: 100%;
                    border-left: .125rem solid #BABABA;
                    position: relative;
                    input{
                        padding-left: 1.5rem;
                    }
                    .icon{
                        position: absolute;
                        left: .3rem;
                        top: 0rem;
                        color: #BABABA;
                        font-size: 1.125rem;
                        line-height: 2.0625rem;
                    }
                }
            }
            .ant-select-selector{
                padding: 0 .5rem;
                border: none;
            }
            .label{
                font-size: .75rem;
                color: #9E9E9E;
                width: 3.125rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .ant-divider{
            display: none;
        }
        .content{
            padding: 1.25rem 0;
            height: 28.125rem;
            overflow-y: scroll;
            .list-content{
                flex-wrap: wrap;
                display: flex;
                gap: .75rem .375rem;
            }
            .box-active, .box:hover{
                .checkbox{
                    display: block !important;
                }
            }
            .box{
                width: 10.4375rem;
                height: 13.75rem;
                margin-left: .25rem;
                position: relative;
                z-index: 1;

                .checkbox{
                    width: 2rem;
                    height: 2rem;
                    border: .0625rem solid #CECECE;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: none;
                    text-align: center;
                    line-height: 2rem;
                    color: #07A35A;
                    border-radius: .1875rem;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                box-shadow: 0 0 .3125rem -0.0625rem #999;
                border-radius: .375rem;
                .img{
                    width: 6.6875rem;
                    height: 6.6875rem;
                    margin-top: 1.25rem;
                    overflow: hidden;
                }
                p{
                    width: 100%;
                    padding-left: .625rem;
                    padding-right: .625rem;
                    color: #17151C;
                    font-size: .5625rem;
                    font-weight: 500;
                    line-height: .8125rem;
                    white-space: nowrap;  
                    text-overflow:ellipsis; 
                    overflow:hidden;
                }
            }
        }
    }
}