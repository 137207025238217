.ModalConnect{
    .ant-modal-header{
        margin-bottom: 50px !important;
        .ant-modal-title{
           font-size: 24px;
           font-weight: 600;
           line-height: 28px;
       }
    }
    .ant-modal-content{
        padding: 30px 33px 20px !important;
        border-radius: 25px !important;
        position: relative;
        &::after{
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 74px;
            background-color: #F0F0F0;
        }
    }   
    .ant-modal-body{
        display: flex;
        flex-direction: column;
    }
    .wallet-adapter-button{
        width: 100%;
        height: auto;
        padding: 0;
        background-color: #fff;
        &:hover{
            background-color: #fff !important;
        }
    }
    .wallet-item{
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 10px;
        background-color: #FCFCFC;
        transition: background-color 0.2s;
        &:hover{
            background-color: #f7f7f7;
        }
        .item{
            padding: 16px 26px;
            display: flex;
            align-items: center;
            position: relative;
        }
        .img{
            border: none;
        }
    }
    .wallet-adapter-button-start-icon{
        display: none;
    }
    .img{
        width: 40px;
        height: 40px;
    }
    .name{
        margin-left: 30px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
    }
    .tips{
        font-size: 18px;
        margin: 0.333em 0px;
        color: rgb(169, 169, 188);
    }
}
.airpost{
    .ant-modal-header{
        margin-bottom: 30px !important;
        .ant-modal-title{
           font-size: 16px;
           line-height: 22px;
           text-align: center;
       }
    }
    .ant-modal-content{
        padding: 22px !important;
        &::after{
            top: 58px;
        }
    }  
    .wallet-item{
        margin-bottom: 12px;
        .item{
            padding: 12px;
            .status{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #B17042;
                position: absolute;
                right: 12px;
            }
        }
    }
    .img{
        width: 26px;
        height: 26px;
    }
    .name{
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #000;
    }
    .airpost-btn{
        width: 100%;
        height: 48px;
        margin-top: 30px;
        border: none;
        box-shadow: none;
    }
}