.preivew-correct{
    color: #43B472;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.annotationModal, .answerModal{
    .title{
        margin: 24px 21px 27px;
        font-weight: 500;
        font-size: 20px;
    }
    .text-center{
        text-align: center;
    }
    .mh480{
        max-height: 480px;
    }
    .box{
        width: 100%;
        min-height: 100px;
        padding: 11px 17px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #F2F4F6;
        overflow-y: auto;
        .box{
            padding: 0;
        }
        &:hover{
            background-color: #F2F4F6;
        }
        .last-rate{
            padding: 5px 10px;
            margin-bottom: 14px;
            border-radius: 3px;
            float: left;
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: rgba($color: #915938, $alpha: 0.1);
            color: #915938;
        }
    }
    .btns{
        margin-top: 32px;
        display: flex;
        justify-content: center;
        button{
            width: 266px;
            height: 50px;
            border: none;
            font-weight: 600;
        }
    }
}
.CustomInput{
    padding: 0 50px;
    color: #343434;
    .custom-input{
        padding: 20px;
        border-radius: 0;
        font-size: 16px;
        // border: 1px solid #DCDCDC !important;
        border-bottom: 1px solid #DCDCDC !important;
    }
    .mh{
        max-height: 250px;
    }
    .bd{
        border: 1px solid #DCDCDC !important;
        border-radius: 10px;
    }
    .CustomInput-content{
        margin-top: 100px;
        .check-item{
            margin-left: 10px;
            margin-bottom: 20px;
            display: flex;
            gap: 30px;
            color: #666666;
            div{
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                img{
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .correct-content{
            margin-top: 15px;
            margin-left: 10px;
            color: #262626;
            font-size: 16px;
            line-height: 22px;
        }
        .preivew-correct{
            margin-right: 10px;
            display: inline-block;
        }
    }
}

.CustomRadio{
    padding: 0 50px;
    .ant-radio-group{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    }
    
    .ant-radio-wrapper{
        margin-bottom: 40px;
        align-items: center;
        font-size: 20px;
        color: #373737;
        line-height: 43px;
        .ant-radio{
            margin-right: 15px;
        }
        .ant-radio-checked .ant-radio-inner{
            background-color: #8F5A35;
            border: none;
        }
        .ant-radio-inner{
            width: 35px;
            height: 35px;
            &::after{
                width: 10px;
                height: 16px;
                margin: 0;
                background-color: #ffffff00;
                top: 23%;
                left: 32%;
                transform: rotate(45deg) scale(1) !important;
                border: 2px solid #fff;
                border-top: 0;
                border-left: 0;
                border-radius: 0;
            }
        }
    }
    .preivew-correct{
        margin-left: 10px;
    }
}

.CustomCheckbox{
    padding: 0 50px;
    .custom-checkbox{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    }
    .ant-checkbox-wrapper-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgba($color: #8F5A35, $alpha: 0.8) !important;
    }
    .css-dev-only-do-not-override-ixblex.ant-checkbox-checked .ant-checkbox-inner{
        border-color: #8F5A35 !important;
    }
    .ant-checkbox-wrapper{
        margin: 0;
        margin-inline-start: 0 !important;
        margin-bottom: 40px;
        align-items: center;
        font-size: 20px;
        color: #373737;
        line-height: 43px;
        .ant-checkbox{
            margin-right: 15px;
        }
        .ant-checkbox, .ant-checkbox-inner{
            width: 30px;
            height: 30px;
        }  
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #8F5A35;
        }
        .ant-checkbox-inner::after{
            width: 10px;
            height: 16px;
        }
    }
    .preivew-correct{
        margin-left: 20px;
    }
}

.reload-tips{
    .ant-tooltip-content .ant-tooltip-inner{
        padding: 10px 17px;
        color: #454545;
        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
    }
}

.CustomCode{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #F9F9F9;
    .code-left, .code-out{
        width: calc(50% - 5px);
    }
    .code-left{
        background-color: #fff;
        border-radius: 10px;
    }
    .code-desc{
        padding-left: 50px;
        .markdown-body{
            font-size: 16px;
            padding-bottom: 50px;
        }
    }
    .code-desc, .code-content{
        height: calc(100% - 90px);
    }
    .code-out{
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .out-inner{
        flex: 1;
        margin-bottom: 10px;
        padding-top: 55px;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .icon-reload{
            width: 26px;
            height: 26px;
            position: absolute;
            right: 27px;
            top: 12px;
            cursor: pointer;
        }
        .reload-tips{

        }
    }
    .preview-menu{
        height: 30px;
        .menu{
            position: absolute;
            left: 24px;
            top: 9px;
            display: flex;
            color: #5D5D5D;
            font-size: 14px;
            line-height: 16px;
            li{
                height: 25px;
                padding-bottom: 5px;
                margin-right: 20px;
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #5D5D5D;
            }
            .green{
                color: #43B472;
            }
            span{
                color: #999999;
            }
        }
    }
    .out-content{
        height: 290px;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
    }
    .CustomConsole{
        .tab ul{
            height: 40px;
            padding: 14px 18px 0;
            display: flex;
            background-color: #fff;
            border-bottom: 1px solid #F7F7F7;
            li{
                margin-right: 25px;
                padding-bottom: 5px;
                color: #5D5D5D;
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #262626;
                color: #262626;
            }
        }
        .console, .case{
            height: 185px;
            margin: 10px 18px;
            textarea{
                height: 100%;
                min-height: 100%;
                border-radius: 10px;
                border-color: #DCDCDC;
            }
        }
        .none{
            display: none;
        }
    }
    .btns{
        padding: 0 18px 0 30px;
        display: flex;
        justify-content: space-between;
        .ant-dropdown-trigger{
            line-height: 32px;
            color: #43B472;
        }
        button{
            padding: 0 20px;
            color: #8F5A35;
            border: 1px solid #8F5A35;
            border-radius: 3px;
            display: flex;
            align-items: center;
            .anticon-caret-right{
                font-size: 18px;
            }
        }
    }
}

.example-dropdown{
    strong{
        font-size: 12px;
        font-weight: 500;
        color: #3D3D3D;
        line-height: 17px;
    }
    .example{
        font-size: 12px;
        color: #666666;
        line-height: 17px;
    }
}