.ant-modal-mask{
    left: 0;
    right: 0;
}
.ant-modal-wrap{
    left: 0;
    right: 0;
}
.ant-message{
    transform: none !important;
    left: 0 !important;
    top: 8px !important;
}
.ant-progress-text{
    left: 0;
    top: 50%;
}