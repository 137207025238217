.UserEdit{
    width: 834px;
    margin: 0 auto;
    padding-block: 82px;
}
.UserEdit-inner{
    padding-top: 30px;
    position: relative;
    .title{
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
    }
    .content{
        .avatar{
            display: flex;
            align-items: center;
            gap: 37px;
            .img{
                width: 76px;
                height: 76px;
                border-radius: 50%;
                overflow: hidden;
            }
            .avatar-uploader{
                width: auto;
            }
            .ant-upload.ant-upload-select{
                width: auto;
                height: auto;
                background-color: #00000000;
                border: none;
            }
            .btn{
                height: 28px;
                margin-top: 14px;
                padding-inline: 26px;
                color: #6C6C6C;
                font-size: 12px;
                // font-weight: 300;
                border-radius: 24px;
            }
        }
        .inner{
            margin-top: 30px;
            .label{
                margin-bottom: 20px;
                font-size: 16px;
                color: #403A35;
                line-height: 22px;
            }
            .input{
                height: 40px;
                padding-inline: 23px;
                border-radius: 10px;
            }
            input{
                font-size: 16px;
                line-height: 24px;
            }
            textarea{
                padding: 17px 32px;
                border-radius: 10px;
                position: relative;
                
            }
            .ant-input-textarea-show-count::after{
                position: absolute;
                right: 12px;
                bottom: 5px;
            }
            .list{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .item{
                    padding: 20px 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 2px solid #E2E2E2;
                    border-radius: 10px;
                    .item-label{
                        display: flex;
                        align-items: center;
                        gap: 18px;
                        img{
                            width: 26px;
                            height: 26px;
                        }
                        svg{
                            font-size: 26px;
                        }
                        .icon-discord{
                            color: #5962ed;
                        }
                        .icon-copy svg{
                            margin-left: 10px;
                            font-size: 14px;
                        }
                    }
                }
                .highline{
                    box-shadow: 0px 4px 8px 0px rgba(143,90,53,0.5);
                    border: 2px solid #8F5A35;
                }
                button{
                    min-width: 170px;
                    height: 40px;
                    border: none;
                    box-shadow: none;
                    font-size: 15px;
                    font-weight: 600;
                }
                .isBind{
                    background-color: #FBF8F6 !important;
                    color: #999999 !important;
                }
            }
        }
    }
}
.UserEdit-btns{
    position: absolute;
    right: 0;
    top: 44px;
    .btn{
        height: 42px;
        margin-right: 22px;
        padding-inline: 30px;

        font-weight: 500;
        line-height: 20px;
        border-radius: 21px;
        border: none;
        box-shadow: none;
        &:last-child{
            margin: 0;
        }
    }
    .save{
        background-color: #000;
        color: #fff;
    }
}