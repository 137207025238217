.Mobile{
    .Claim{
        .waiting{
            p{
                width: 100vw;
                padding: 0 5%;
                font-size: 1.3rem;
            }
            #hover-btn-line{
                margin-top: 3.25rem;
            }
            .btn-link{
                margin-top: 9.375rem;
            }
        }
    }
}

.Claim{
    .waiting{
        margin: 240px auto 0;
        text-align: center;
        .box{
            margin-bottom: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-wait{
                width: 20px;
                height: 20px;
                margin-right: 22px;
            }
            .icon-info{
                width: 14px;
                height: 14px;
                margin-right: 3px;
            }
        }
        .box-center{
            margin-top: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            #hover-btn-full{
                border: none;
            }
            button{
                min-width: 160px;
                height: 38px;
            }
        }
        p{
            width: 450px;
            font-size: 26px;
            line-height: 36px;
        }
        .tip{
            color: #999999;
            font-size: 14px;
        }
        .btn-link{
            margin: 200px auto 0;
            display: block;
            font-size: 16px;
            color: #000000;
            span{
                text-decoration: underline;
            }
        }
    }
    .claim-loading{
        margin: 200px auto 0;
        min-height: calc(100vh - 300px);
        text-align: center;
    }
    .hide{
        display: none;
    }
    .qrcode-box{
        .ant-popover-inner{
            padding: 0;
        }
        // 二维码
        .qrcode{
            width: 170px;
            padding: 33px 0 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .qrcop{
                .ant-spin-blur{
                    opacity: 0.1;
                }
            }
            p{
                font-size: 16px;
                color: #9FAEC2;
                line-height: 19px;
            }
            .anticon-wechat{
                margin-right: 6px;
                font-size: 20px;
                color: #36C160;
            }
        }
    }
    
}
.Claim{

.CustomCompleted{
    .completed-content{
        width: 956px;
        margin: 0 auto;
        margin-block: 100px;
    }
    .desc{
        .title{
            color: #000;
            font-size: 36px;
            font-weight: 500;
            line-height: 58px;
        }
        .zk{
            margin-top: 24px;
            font-size: 16px;
            color: #666666;
            span{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .score{
        margin-top: 100px;
        display: flex;
        .circle {
            user-select: none;
            .ant-progress-inner, .ant-progress-circle{
                overflow: visible !important;
            }
            foreignObject div div{
                border-radius: 10px;
                overflow: hidden;
            }
            .pass{
                foreignObject div div{
                    background: conic-gradient(from 180deg, rgb(90, 214, 141) 0%, rgb(67, 180, 114) 100%) !important;
                }
            }
            .unpass{
                foreignObject div div{
                    background: conic-gradient(from 180deg, #F46565 0%, #B64444 100%) !important;
                }
            }
            // .ant-progress-circle-path{
                // stroke-linecap: round;
            // }
            .pass .ant-progress-circle-path{
                transform: rotate(90deg) !important;
            }
            .unpass .ant-progress-circle-path{
                transform: rotate(90deg) !important;
            }
            .ant-progress-text{
                font-size: 64px;
                font-weight: 500;
                line-height: 90px;
            }
            .text{
                width: 100%;
                text-align: center;
                color: #666666;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                position: absolute;
                bottom: -25px;
            }
            .text-unpass{
                font-size: 40px;
                font-weight: 500;
            }
        }
        .info{
            margin-left: 145px;
            .network{
                max-width: calc(956px - 145px - 230px);
                margin-bottom: 16px;
                font-size: 30px;
                font-weight: 500;
                line-height: 43px;
                -webkit-line-clamp: 1;
            }
            .pass{
                font-size: 16px;
                color: #9E9E9E;
                line-height: 26px;
                font-weight: 500;
            }
            .btns-flex{
                display: flex;
                gap: 40px;
                #hover-btn-full{
                    border: none;
                }
                button{
                    min-width: 160px;
                }
            }
        }
        
    }
    .annotation{
        width: 500px;
        margin-left: 355px;
        .left{
            max-width: 90%;
        }
        .annotation-title{
            width: 100%;
            font-size: 18px;
            line-height: 30px;
            border-bottom: 1px solid #000;
        }
        .annotation-item{
            padding: 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #000;
            cursor: pointer;
            p{
                min-height: 20px;
                line-height: 20px;
            }
        }
    }
    .btn{
        height: 40px;
        margin-top: 80px;
        padding-inline: 32px;
        border-radius: 8px;
        border: 1px solid #8F5A35;
        box-shadow: none;
        font-size: 16px;
        color: #8F5A35;
        line-height: 26px;
    }
    .viewer{
        margin-top: 100px;
        .viewer-head{
            font-size: 20px;
            font-weight: 500;
            line-height: 29px;
        }
        .ant-divider{
            margin: 10px 0 15px;
            border-top: 2px solid #E8E8E8;
        }
        .viewer-content{
            min-height: 500px;
            padding: 0 20px;
            font-size: 16px;
            line-height: 23px;
        }
    }
    .content-step{
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        h5{
            margin: 0;
            font-size: 23px;
            font-weight: 500;
            color: #212429;
            line-height: 35px;
        }
        .nft{
            .sbt{
                width: 100%;
                height: 100%;
            }
            .img{
                width: 300px;
                height: 300px;
                margin-top: 75px;
                position: relative;
                border-radius: 22px;
                overflow: hidden;
                .icon{
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    position: absolute;
                    right: 13px;
                    top: 11px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .img-mask{
                    width: 100%;
                    height: 70px;
                    padding: 0 17px;
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
                    display: flex;
                    align-items: center;
                    .newline-omitted{
                        color: #fff;
                        font-size: 19px;
                        font-weight: 600;
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
        .step{
            width: 591px;
            position: relative;
            // 自定义step type
            .tips{
                position: absolute;
                top: 170px;
                left: 46px;
                font-weight: 500;
            }
            .step-detail{
                margin-top: 40px;
                .ant-steps-item-finish .custom-dot .dot{
                    background-color: #B17042;
                }
                .ant-steps-item-container{
                    position: relative;
                }
                .custom-dot{
                    width: 6px;
                    position: absolute;
                    left: 8px;
                    bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .dot{
                        width: 6px;
                        height: 6px;
                        margin-bottom: 12px;
                        border-radius: 50%;
                        background-color: #D1D1D1;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                .ant-steps-item-icon{
                    width: 22px;
                    height: 22px;
                    margin-right: 19px;
                    left: 0 !important;
                }
                .ant-steps-item-tail{
                    display: none !important;
                }
                // 结束
                .ant-steps-item-finish{
                    .ant-steps-icon-dot{
                        background: url("../../images/icon/finish.png") no-repeat center;
                        background-size: cover;
                    }
                    .step-box{
                        background-color: #D6B59E;
                        color: #fff;
                    }
                } 
                // 正在
                .ant-steps-item-active{
                    .ant-steps-icon-dot{
                        background: url("../../images/icon/active.png") no-repeat center;
                        background-size: cover;
                    }
                    .step-box{
                        background-color: #B17042;
                        color: #fff;
                    }
                } 
                // 等待
                .ant-steps-item-wait{
                    .ant-steps-icon-dot{
                        background: url("../../images/icon/wait.png") no-repeat center;
                        background-size: cover;
                    }
                    .step-box{
                        background-color: #F0F0F0;;
                        color: #A3A3A3;
                        button{
                            background-color: #fff;
                        }
                        .box button{
                            background-color: #F0F0F0;
                        }
                    }
                }
                .ant-steps-item-description{
                    padding-bottom: 62px;
                }
            }
        }
    }
}

.step-box{
    width: 550px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B17042;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    .mw-140{
        min-width: 140px;
    }
}

.checked-step{
    background-color: #B17042;
    color: #fff;
    .box{
        background-color: #B17042;
        border: none;
    }
    .box .claim{
        background-color: #B17042;
        color: #fff;
        .anticon-twitter{
            color: #fff !important;
        }
    }
}

.position{
   position: relative;
   .tips{
        position: absolute;
        right: -70px;
        bottom: 7px;
        color: #999999;
        font-size: 50px;
        font-weight: 400;
   }
   
}

.innerHref{
    margin-left: 42px;
    padding-inline: 12px 10px;
    justify-content: space-between;
    font-weight: 500;
    border: 1px solid #B17042;

    input{
        width: 384px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #B17042;
        color: #B17042;
        font-size: 20px;
        line-height: 29px;
    }
    button{
        width: 112px;
        height: 50px;
        color: #B17042;
        font-size: 16px;
        line-height: 23px;
    }
}
.CustomBox{
    padding-inline: 20px 10px;
    justify-content: space-between;
    button{
        height: 42px;
        padding-inline: 25px;
        border-radius: 8px;
        border: none;
        box-shadow: none;
        color: #B17042;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        p{
            padding: 0 !important;
        }
        .icon{
            font-size: 26px;
        }
        .discord{
            color: #5865F2;
        }
        .wechat{
            color: #00DA6B;
        }
    }
    p:last-child{
        font-size: 16px;
        padding-inline: 25px;
    }
    .is-bind{
        border: none;
        color: #fff;
        background-color: #D6B59E;
        .icon{
            color: #fff;
        }
    }
}
.pl70{
    padding-left: 70px;
    position: relative;
    .icon{
        width: 35px;
        position: absolute;
        left: 20px;
        img{
            width: 100%;
        }
    }
}

.flex{
    padding: 0;
    display: flex;
    gap: 30px;
    background-color: #ffffff00 !important;
    button{
        width: 100%;
        height: 55px;
        border: 1px solid #F0F0F0;
        color: #F0F0F0;
        font-weight: 400;
    }
}

.ant-steps-item-finish{
    .flex,.CustomBox{
        button{
            background-color: #fff;
            border-color: #B17042;
            color: #444444;
        }
        .is-bind{
            border: none;
            color: #fff;
            background-color: #D6B59E;
        }
    }
}

.checked-flex{
    button{
        border-color: #B17042 !important;
        color: #444444;
    }
}


.CustomDiscord{
    padding-inline: 41px 14px;
    justify-content: space-between;
}

.CustomCliam{
    padding: 0;
    background-color: #ffffff00 !important;
    border: none;
    .ant-ribbon-text{
        font-size: 10px;
    }
    .box{
        width: 227px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff00;
        border-radius: 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        p{
            width: 57px;
        }
        .share{
            padding-inline: 13px 22px;
        }
        button{
            width: 100%;
            height: 48px;
            border-radius: 10px;
            font-size: 18px;
            .anticon-twitter{
                font-size: 16px;
            }
        }

        .ant-steps-item-active{
            .claim .anticon-twitter{
                color: #0495d7;
            }
            
        }
        // 等待
        .ant-steps-item-wait{
            .claim .anticon-twitter{
                color: #999999;
            }
        }
    }
        
}
.isClaim{
    // background-color: #B17042;
    justify-content: center;
    color: #B17042;
    border: 1px solid #B17042;
}
}
.tooltip{
    max-width: 290px !important;
}
.tip-content{
    width: 100%;
    padding-inline: 23px;
    padding-block: 8px 27px;
    color: #17151C;
    line-height: 20px;
    .step{
        color: #9E9E9E;
    }
    span{
        color: #727272;
    }
}