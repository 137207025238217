.Mobile{
    .ChallengeItem{
        width: 100%;
        height: 7.8125rem;
        padding: .8125rem;
        border-width: .0625rem;
        border-radius: .625rem;
        transition: none;
        .item-claimed, .item-claimable{
          width: 2.8125rem;
          height: 1.125rem;
          left: -0.0625rem;
          top: -0.0625rem;
          line-height: 1.125rem;
          font-size: .5rem;
          border-radius: .3125rem;
          border-bottom-left-radius: 0;
        }
        .left-info {
          width: calc(100% - 6.125rem - .625rem);
          .title {
            margin-bottom: .4375rem;
            font-size: .75rem;
            line-height: .875rem;
          }
          .desc{
            font-size: .75rem;
            line-height: .875rem;
          }
          .collection-author{
            .img{
              width: 1.25rem;
              height: 1.25rem;
            }
            p{
              margin-left: .4375rem;
              font-size: .875rem;
              line-height: 1.25rem;
            }
          }
          .sbt-detail{
            font-size: .75rem;
            .flex{
              gap: .3125rem;
            }
            img{
              width: .875rem;
              height: .875rem;
              margin-right: .25rem;
            }
            .time{
              line-height: .625rem;
              .anticon{
                margin-right: .5rem;
                font-size: .75rem;
              }
            }
          }
        }
        .right-sbt {
          width: 6.125rem;
          height: 6.125rem;
          border-radius: .1875rem;
          .img-mask{
            height: 1.3125rem;
            padding: 0 .3125rem;
            font-size: .375rem;
          }
          .opensea{
            width: 1rem;
            height: 1rem;
            right: .125rem;
            top: .125rem;
          }
        }
    }
}