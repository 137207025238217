.Cert{
    width: 1660px;
    // min-height: 1080px;
    margin: 82px auto 0;
    display: flex;
    position: relative;
    .provide{
        position: absolute;
        right: 0;
        bottom: 17px;
        color: #B4B5C5;
        font-size: 12px;
        display: flex;
        align-items: center;
        strong{
            padding: 0 10px 6px;
            font-weight: bolder;
            font-size: 30px;
            line-height: 15px;
        }
    }
}
.header-line{
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
    position: fixed;
    top: 82px;
    left: 0;
}
.divider{
    width: 370px;
    margin-block: 33px;
    border-color: #D8D8D8;
}
.mt50{
    margin-top: 50px;
}
.Cert-sidbar{
    width: 380px;
    margin-right: 30px;
    padding-top: 40px;
    box-sizing: content-box;
    .search{
        .search-title{
            margin-bottom: 15px;
            color: #03111D;
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
        }
        .search-inner{
            height: 50px;
            padding: 11px 18px;
            padding-right: 0;
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #D8D8D8;
        }
        .icon{
            margin-right: 18px;
            color: rgba(0,0,0,0.5);
            font-size: 14px;
        }
        input{
            padding: 0;
            font-size: 20px;
        }
    }
    .user{
        .user-content{
            display: flex;
            align-items: center;
        }
        .avatar > .img{
            width: 98px;
            height: 98px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .user-info{
            margin-left: 20px;
            .name{
                max-width: 200px;
                margin-bottom: 14px;
                font-size: 20px;
                font-weight: 600;
                line-height: 23px;
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
                word-break: break-all;
            }
            .address{
                max-width: 200px;
                color: #999999;
                font-size: 14px;
                line-height: 20px;
                cursor: pointer;
                .anticon-copy{
                    margin-left: 15px;
                    color: #000;
                }
            }
        }
        .social{
            margin-top: 35px;
            display: flex;
            align-items: center;
            .items{
                width: 310px;
                display: flex;
                .item{
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    transition: box-shadow 0.3s;
                    &:hover{
                        box-shadow: 0 2px 3px 1px rgba($color: #000000, $alpha: 0.1);
                    }
                    img{
                        width: 35px;
                    }
                }
            }
            .ant-divider-vertical{
                height: 24px;
                margin: 0;
                background-color: #E4E4E4;
            }
            .share{
                width: 23px;
                height: 23px;
                margin-left: 30px;
                padding: 0;
                border: none;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                &:hover{
                    background-color: #9999999a;
                    color: #9999999a !important;
                }
            }
        }
    }
    .line{
        margin: 40px auto 35px;
        background-color: #D8D8D8;
    }
    .nfts{
        .add{
            margin-bottom: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                font-size: 18px;
                font-weight: 600;
                line-height: 21px;
            }
            button{
                width: 28px;
                height: 28px;
                padding: 0;
                color: #fff;
                font-size: 19px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #8F5A35;
                border-radius: 50%;
                border: none;
            }
        }
        ul{
            max-height: 30vh;
            .active,li:hover{
                background-color: rgba($color: #F8F6F5, $alpha: 1);
                p{
                    color: #000;
                }
            }
            li{
                height: 50px;
                padding: 5px 10px;
                margin-bottom: 5px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                transition: background-color 0.2s;
                .poa{
                    position: absolute;
                    right: 25px;
                }
                p{
                    color: #666666;
                    font-size: 14px;
                    font-weight: 500;
                }
                .li-content{
                    max-width: calc(100% - 85px);                
                    white-space: nowrap;  
                    text-overflow:ellipsis; 
                    overflow:hidden;
                }
                .img{
                    width: 43px;
                    height: 43px;
                    margin-right: 12px; 
                    border-radius: 5px;
                    position: relative;
                    .badge{
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        right: -8px;
                        top: -8px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
.dropdown:hover{
    .nft-detail .nft-info .bottom{
        bottom: 0;
    }
}

.Cert-content{
    width: calc(100% - 410px);
    padding-block: 40px 50px;
    border-left: 1px solid #D8D8D8;
    position: relative;
    .content-header, .content-header-mobile{
        display: flex;
        justify-content: space-between;
    }
    ul{
        padding-inline: 40px;
        display: flex;
        color: #666666;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        li{
            margin-right: 50px;
            cursor: pointer;
        }
        .active{
            color: #000;
            font-weight: 500;
        }
    }
    .reload{
        height: 44px;
        padding: 0 18px 0 12px;
        font-size: 15px;
        color: #353840 !important;
        background-color: #F6F6F6;
        border: none;
        img{
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }
    }
    .nfts{
        padding: 0 20px;
        .nodata{
            width: 100%;
            height: 480px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p{
                font-size: 80px;
                font-weight: 900;
                color: rgba($color: #7a7e86, $alpha: 0.1);
                line-height: 120px;
                user-select: none;
            }
            button{
                width: 176px;
                height: 44px;
                margin-top: 20px;
                border-radius: 10px;
                border-color: #000;
                box-shadow: none;
            }
        }
        .scroll{
            width: 1243px;
            padding: 43px 0 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 33px;
            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari 和 Opera */
            }
        }
        .loading{
            width: 100%;
            padding-block: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                margin-inline: 15px;
            }
        }
        .flex{
            width: 100%;
            padding-left: 9px;
            display: flex;
            gap: 19px;
            position: absolute;
            top: 12px;
            left: 0;
        }
        .nft-detail{
            width: 282px;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #F0F0F0;
            position: relative;
            &:hover{
                .nft-info .bottom{
                    bottom: 0;
                }
                .badge{
                    opacity: 1;
                }
            }
            .badge{
                width: 32px;
                height: 32px;
                // position: absolute;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
                transition: opacity 0.2s;
                font-size: 16px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .badge-chain{
                left: 9px;
                top: 12px;
            }
            .badge-opensea{
                left: 60px;
                top: 12px;
            }
            .badge-eye{
                position: absolute;
                right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
            }
            .eye-show{
                color: #808080;
                background-color: rgba($color: #fff, $alpha: 0.3);
            }
            .eye-hide{
                color: #fff;
                background-color: rgba($color: #000, $alpha: 0.3);
            }
            .show .badge{
                opacity: 1;
            }
            .img{
                width: 100%;
                height: 280px;
                background-color: rgba(0,0,0,0.01);
                video, img{
                    width: 100%;
                    height: auto;
                }
            }
            .nft-info{
                width: 100%;
                height: 80px;
                background-color: #fff;
                position: relative;
                .nft-title{
                    width: 100%;
                    padding: 16px 21px 0 12px;
                    color: #17151C;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                }
                .bottom{
                    width: 100%;
                    height: 36px;
                    position: absolute;
                    bottom: -36px;
                    color: #fff;
                    background-color: #B47243FF;

                    transition: bottom 0.2s;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        width: calc(100% - 60px);
                        text-align: center;
                    }
                    .more{
                        width: 60px;
                        height: 100%;
                        border-left: 1px solid #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .icon{
                            font-size: 20px;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}