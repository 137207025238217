body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin-bottom: 0;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.challenge-img{
  background-color: #FCFCFC;
  border: 1px solid #F1F1F1;
}
.img{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img > img{
  /* width: 100%; */
  /* height: 100%; */
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  /* object-fit: cover; */
}

.ant-divider{
  margin: 0;
}

.ant-menu{
  border-inline-end: none !important;
}

.custom-scroll{
  overflow-y: scroll;
}
.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #F0F0F0;
  border-radius: 6px;
}

.newline-omitted{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}