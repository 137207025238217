.Explore {
    width: 1440px;
    padding-top: 170px;
    margin: 0 auto;
    font-family: Source;
    position: relative;
    .back{
      position: absolute;
      display: flex;
      align-items: center;
      top: 95px;
      font-size: 20px;
      cursor: pointer;
      .anticon{
        margin-right: 11px;
        font-size: 30px;
      }
    }
    // title
    h3 {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
    }
    .search, .selectList, .content-subtitle{
      margin-bottom: 20px;
    }
    .content-subtitle{
      font-size: 16px;
      color: #191919;
      font-weight: 600;
    }
    // depass
    .depass {
      margin-top: 63px;
      h4 {
        font-size: 20px;
        font-weight: 800;
        color: #3d3d3d;
      }
    }
    // challenges
    .challenges {
      padding: 0;
      padding-bottom: 150px;
      display: flex;
      flex-wrap: wrap;
      gap: 36px 40px;
    }
}
  