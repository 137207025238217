.CustomQuestion{
    margin-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .info{
        width: 80%;
        display: flex;
    }
    .content{
        width: 98%;
        padding-left: 20px;
        p{
            white-space: nowrap;  
            text-overflow:ellipsis; 
            overflow:hidden;
        }
        .question-title{
            white-space: pre-wrap;
        }
    }
    .operation{
        padding-right: 15px;
        display: flex;
        font-size: 18px;
    }
    .close{
        margin-left: 25px;
        cursor: pointer;
    }
}