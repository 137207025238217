#layout-header{
    width: 100%;
    padding: 0;
    background-color: rgba(0,0,0,0);
    position: fixed;
    color: #fff;
    z-index: 999;
}

#layout-content{
    overflow: hidden;
    display: grid;
}

#layout-footer{
    height: auto;
    padding: 0;
    text-align: center;
    color: #fff;
    background-color: #000;
}

#Header{
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(42px);
    &,.nav-left a{
        color: #000;
        transition: color 0.2s, font-weight 0.2s;
    }
    .nav-left a:hover, .nav-left .active{
        font-weight: bold;
        color: #8F5A35;
    }
    .user{
        color: #8F5A35;
        border: 1px solid #8F5A35;
    }
    .header-content{
        width: 100%;
        padding-left: 150px;
        padding-right: 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nav-left, .nav-right{
        display: flex;
        align-items: center;
        gap: 63px;
    }
    .nav-left > a{
        font-size: 17px;
        font-weight: 400;
    }
    .logo{
        width: 146px;
        height: 100%;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin-right: 10px;
        }
    }
    .nav-right{
        width: auto;
        display: flex;
        gap: 20px;
        justify-content: space-between;
    }
    .custom-btn{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border-width: 1px;
        border-style: solid;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 26px;
            height: 26px;
        }
    }
    .lang{
        font-size: 18px;
    }
    .connect{
        width: 160px;
        height: 40px;
        background-color: #8F5A35;
        border-radius: 10px;
        color: #fff;
        font-size: 17px;
        border: none;
        box-shadow: none;
    }
    .user{
        height: 40px;
        padding-right: 16px;
        border-radius: 10px;
        font-size: 17px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
            width: 32px;
            height: 32px;
            margin-left: 4px;
            overflow: hidden;
            border-radius: 50%;
        }
        p{
            margin-left: 10px;
        }
    }
    .test{
        width: 200px !important;
    }
        .menu-item{
            width: 100px;
        }
}

#Footer{
    color: #ffffff;
    font-family: Source;
    .footer-content{
        padding: 44px 240px 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
            text-align: left;
            .logo{
                width: 130px;
                height: 30px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            p{
                margin-top: 12px;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
            }
        }
        .right{
            a{
                margin-right: 32px;
                color: #fff;
                font-weight: 600;
                &:hover{
                    text-decoration: underline;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .ant-divider{
        margin: 0;
        border-color: #1D1D1D;
    }
    .footer-record{
        margin: 41px auto 53px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        a{
            text-decoration: underline #fff ;
        }
    }
}

.modalSigner{
    .ant-modal-content{
        background: none;
    }
    .ant-modal-content{
        background-color: #222832 !important;
    }
    .ant-modal-confirm-title{
        color: #fff !important;
        text-align: center;
    }
}

.wallet-solana{
    display: flex;
    align-items: center;
    border-width: 1px;
    padding: 5px;
    gap: 12px;
    cursor: pointer;
    font-weight: 700;
    color: #25292E;
    border-radius: 12px;
    transition: background-color 0.125s ease;
    &:hover{
        background-color: #eaebeb;
    }
    img{
        width: 28px;
        height: 28px;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 6px;
    }
}

.wallet-solana-mobile{
    padding: 0 20px;
    font-weight: 500;
    color: #25292E;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    img{
        width: 60px;
        height: 60px;
        padding: 5px;
        margin: 10px auto 8px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
        border-radius: 13px;
        background-color: #fff;
    }
}

.challenge-menu{
    .ant-dropdown-menu{
        min-width: 152px;
        height: 85px;
        .ant-dropdown-menu-item{
            height: 36px;
        }
    }
}

.markdown-body{
    font-size: 19px;
    p{
        line-height: 23px;
    }
    thead{
        background: #f6f6f6;
        color: #000;
        text-align: left;
    }
    th{
        padding: 12px 7px;
        line-height: 24px;
    }
    td{
        padding: 12px 7px;
        line-height: 24px;
    }
    tr:nth-child(2n){
        background-color: #fcfcfc;
    }
    table {
        display: inline-block ;
        font-size: 12px;
        width: auto;
        max-width: 100%;
        overflow: auto;
        border: 1px solid #f6f6f6;
        border-collapse: separate;
        text-indent: initial;
        border-spacing: 2px;
    }

    code {
        font-family: monospace;
        background-color: #f7f7f7;
        padding: 0.2em 0.4em;
        display: inline-block;
    }

    blockquote{
        margin: initial;
        padding: initial;
        border: initial;
        margin-left: 40px;
        padding-left: 20px;
        border-left: 2px solid #ccc;
    }

    .task-list-item{
        list-style: none;
    }

    ul{
        padding-left: 40px;
        list-style-type: disc;
    }
}

// 页面顶部背景
.custom-bg-round{
    width: calc(1400px / 1920px * 100%);
    height: 400px;
    background: #B27347;
    border-radius: 50%;
    opacity: 0.4;
    filter: blur(70px);
    position: absolute;
    top: -300px;
    left: calc((100% - (1400px / 1920px * 100%)) / 2);
}

// header下拉菜单
.mobile-custom-drop-menu{
    .ant-dropdown-menu-item-divider:first-child{
        display: none;
    }
}
.custom-dropmenu{
    .ant-dropdown-menu{
        padding: 8px 6px !important;
        // box-shadow: none !important;
    }
    .ant-dropdown-menu-item{
        height: 50px;
        padding-left: 14px !important;
    }
    .ant-dropdown-menu-title-content{
        font-weight: 500;
    }
    .ant-dropdown-menu-item-divider{
        margin: 5px 0 !important;
        background-color: #E9E9E9 !important;
    }
}

.modal-tip{
    padding-bottom: 0 !important;  
    text-align: center; 
    .ant-modal-confirm-btns{
        margin-top: 50px !important;
        display: flex;
        justify-content: center;
        button{
            height: 34px;
            padding: 0 46px;
            background-color: #8F5A35;
            border-radius: 20px;
            font-weight: 600;
            border: none !important;
            &:hover{
                background-color: #B47243 !important;
            }
        }
    }
    .ant-modal-content{
        padding: 47px 0 39px !important;
        border-radius: 20px !important;
    }
    .ant-modal-confirm-body{
        width: 100%;
        justify-content: center !important;
    }
    .tip-content{
        padding: 0;
        color: #8A8E8E;
        font-size: 15px;
        line-height: 18px;
    }
    .custom-icon{
        margin-bottom: 48px;
        font-size: 50px;
        line-height: 59px;
    }
    .modal-close{
        width: 18px;
        height: 18px;
        border: none;
        font-size: 18px;
        padding: 0;
        position: absolute;
        right: 26px;
        top: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .share-link{
        padding: 0 28px;
        h3{
            margin-bottom: 45px;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
        }
        .link{
            height: 40px;
            margin-bottom: 47px;
            padding: 0 10px;
            background-color: #F9F9F9;
            border-radius: 10px;
            color: #999999;
            line-height: 40px;
            white-space: nowrap;  
            text-overflow:ellipsis; 
            overflow:hidden;
        }
        button{
            width: 162px;
            height: 40px;
        }
    }
}

.notfound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        width: 360px;
        height: 158px;
        margin-bottom: 65px;
    }
    .notfound-content{
        margin-bottom: 35px;
        color: #0A0A0A;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
    }
    .btn{
        height: 50px;
        padding: 0 35px;
        border-radius: 8px;
        box-shadow: none;
        font-size: 16px;
    }
}

// hover效果
#hover-btn-full{
    background-color: #8F5A35;
    color: #fff;
    &:hover{
        background-color: #B47243;
    }
}
#hover-btn-line{
    border-color: #8F5A35;
    color: #8F5A35;
    span{
        color: #8F5A35;
    }
    &:hover, span:hover{
        color: #B47243;
        border-color: #B47243;
    }
}
#hover-btn-line-arrow{
    border-color: #000;
    span{
        color: #000;
    }
    &:hover{
        span{
            color: #B47243;
        }
        border-color: #B47243;
    }
}
#hover-btn-ghost{
    color: #B17042;
    background-color: #fff;
    &:hover{
        background-color: #fbf8f6;
    }
}
#hover-btn-tofull{
    border-color: #8F5A35;
    color: #8F5A35;
    background-color: #fff;
    &:hover{
        border: none;
        background-color: #B47243;
        color: #fff;
    }
}
#hover-text{ 
    span{
        color: #BABABA;
    }
    button span{
        color: #4E4E4E;
    }
    &:hover{
        &, span{
            color: #B47243;
        }
    }
}