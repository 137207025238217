.ModalLoading{
    .ant-modal-content{
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .claimed{
        padding-block: 96px 83px;
        padding-inline: 205px;
    }
    .claiming{
        padding-block: 120px 100px;
        padding-inline: 154px;
        .loading{
            width: 270px;
            height: 270px;
            background-color: #f9f9fb;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
        }
        .loading-title{
            margin-top: 125px;
            color: #101010;
            font-size: 24px;
            line-height: 28px;
        }
        .loading-tip{
            width: 459px;
            margin-top: 27px;
            text-align: center;
            color: #B17042;
            font-size: 18px;
            line-height: 21px;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img{
            width: 290px;
            height: 290px;
            margin-top: 50px;
            border-radius: 10px;
            overflow: hidden;
        }
        .title{
            color: #666666;
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
        }
        a{
            margin-block: 25px;
            color: #3662EC;
            font-size: 18px;
            line-height: 21px;
            text-decoration: underline;
        }
        .btn{
            width: 250px;
            height: 50px;
            border-radius: 25px;
            border: none;
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            background-color: #3662EC;
        }
    }
}
.ModalLoading-mobile{
    .claimed{
        padding: 2.5rem 6.25rem 1.5625rem;
    }
    .claiming{
        padding: 2.5rem 6.25rem 1.5625rem;
        .loading{
            width: 9.375rem;
            height: 9.375rem;
            border-radius: 1.25rem;
        }
        .loading-title{
            margin-top: 4.0625rem;
            font-size: 1.125rem;
        }
        .loading-tip{
            width: 12.5rem;
            margin-top: 1.6875rem;
            font-size: .75rem;
            line-height: .9375rem;
        }
    }
    .content{
        .img{
            width: 9.375rem;
            height: 9.375rem;
            margin-top: 1.0625rem;
            border-radius: .3125rem;
        }
        .title{
            font-size: 1.125rem;
        }
        a{
            margin-block: 1.0625rem;
            font-size: .875rem;
        }
        .btn{
            width: 9.375rem;
            height: 2.5rem;
            border-radius: 1.25rem;
            font-size: .75rem;
            line-height: 1.0625rem;
        }
    }
}