.dropdown-selectNetwork{
    padding-top: 20px !important;
    // 第一层ul
    .ant-dropdown-menu{
        padding: 20px !important;
        .ant-dropdown-menu-item-group-title{
            padding: 0 !important;
            font-size: 15px;
            font-weight: 500;
            color: #666666 !important;
            line-height: 21px;
        }
        // 第二层ul
        .ant-dropdown-menu-item-group-list{
            margin: 20px 0 0 !important;
            .ant-dropdown-menu-item{
                padding: 6px 8px;
                margin-bottom: 12px;
                &:last-child{
                    margin-bottom: 0;
                }
                .network-item, .item-label{
                    display: flex;
                    align-items: center;
                }
                .network-item{
                    justify-content: space-between;
                }
                .item-label{
                    gap: 10px;
                }
                img{
                    width: 26px;
                    height: 26px;
                }
                .item-status{
                    width: 6px;
                    height: 6px;
                    margin-left: 10px;
                    border-radius: 50%;
                    background-color: #ffffff00;
                }
                .item-status-active{
                    background-color: #D3B683;
                }
            }
        }
    }
}