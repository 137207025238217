.Search{
    padding-block: 230px 390px;
    background-color: #FCFAF9;
    position: relative;
    .round1{
        width: 412px;
        height: 412px;
        top: -30px;
        left: calc((100% - 412px) / 2 - 415px);
        position: absolute;
        background-color: rgba($color: #B27347, $alpha: 0.5);
        opacity: 0.4;
        filter: blur(132px);
        border-radius: 50%;
    }
    .round2{
        width: 632px;
        height: 632px;
        top: 385px;
        right: calc((100% - 632px) / 2 - 420px);
        position: absolute;
        background-color: rgba($color: #B27347, $alpha: 0.5);
        opacity: 0.4;
        filter: blur(132px);
        border-radius: 50%;
    }
    .Search-content{
        text-align: center;
        z-index: 1;
        position: relative;
        .title{
            max-width: 1026px;
            margin: 0 auto;
            img{
                width: 100%;
            }
        }
        .color-primary{
            margin-top: -31px;
            background: linear-gradient(160deg, #CDAF7A 0%, #884E26 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .subtitle{
            color: #777777;
            font-size: 30px;
            line-height: 35px;
        }
        .inner{
            width: 724px;
            height: 60px;
            padding-inline: 25px 6px;
            margin: 62px auto 0;
            display: flex;
            align-items: center;
            border-radius: 60px;
            background-color: #fff;
            .icon{
                margin-right: 20px;
                font-size: 20px;
            }
            input{
                width: calc(100% - 132px - 40px);
                font-size: 15px;
                line-height: 35px;
                &::placeholder{
                    color: #999999;
                }
            }
            button{
                width: 132px;
                height: 48px;
                color: #fff;
                font-size: 16px;
                border-radius: 22px;
                border: none;
                box-shadow: none;
                background-color: #A9814D;
            }
        }
        .example{
            width: 724px;
            padding-left: 44px;
            margin: 20px auto 0;
            display: flex;
            li{
                padding: 2px 13px;
                margin-right: 20px;
                font-weight: 500;
                line-height: 16px;
                border-radius: 20px;
                background-color: #fff;
                cursor: pointer;
                // &:first-child{
                //     color: #A9814D;
                // }
                color: #777777;
            }
        }
    }
}