$container-width: 21.875rem;
.Mobile{
    .Question{
        width: $container-width;
        margin: 5.3125rem auto 4.375rem;
        overflow: hidden;
        h1{
            margin-bottom: .875rem;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.625rem;
        }
        .content{
            flex-direction: column;
            padding-bottom: 3.125rem;
        }
        .content-right{
            width: 100%;
        }
        .title{
            font-size: 1.125rem;
            font-weight: 700;
            span{
                min-width: 2.5rem;
                height: 1.25rem;
                margin-left: .9375rem;
                font-size: .75rem;
                line-height: 1.25rem;
                border-radius: 1.25rem;
            }
        }   
    }
    // content-left
    .quest-title{
        .img{
            width: 21.625rem;
            height: 21.625rem;
            border-radius: 1.25rem;
            .img-mask{
                height: 5rem;
                padding: 0 1.25rem;
                .newline-omitted{
                    font-size: 1.375rem;
                }
            }
        }
    }

    .line{
        width: 100%;
        height: 1px;
        background-color: #F2F3F7;
        display: none;
    }
    .quest-challenger{
        width: 100%;
        margin-top: 1.875rem;
        // padding-bottom: 2.5rem;
        // border-bottom: .0625rem solid #F5F5F5;
        // 选择器
        .selector{
            width: 19.125rem;
            border-radius: 1.25rem;
            .ant-segmented-thumb{
                border-radius: 1.25rem !important;
            }
            .ant-segmented-item{
                width: 6.25rem;
                border-radius: 1.25rem !important;
                .ant-segmented-item-label{
                    height: 2.125rem;
                    line-height: 2.125rem;
                }
            }
        }
        // 标题
        .label{
            margin-top: 1.25rem;
            padding: .625rem 0;
            font-size: .875rem;
            line-height: 1.125rem;
        }
        .list {
            min-height: 5rem;
        }
        ul{
            margin-top: .625rem;
            gap: .3125rem;
            li{
                height: 3.4375rem;
                padding-left: 0;
                padding-right: 0;
                .info{
                    padding-left: .5rem;
                    gap: .5rem;
                    .no{
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                    .addr{
                        font-size: .8125rem;
                    }
                }
                .res{
                    .score{
                        font-size: .875rem;
                    }
                    .c9{
                        font-size: .75rem;
                    }
                }
            }
            .my-rank{
                padding-left: 0;
                border-left: .125rem solid #8F5A35;
                .info{
                    padding-left: .5rem;
                }
            }
        }
    }
    
    // content-right
    .quest-info{
        .li{
            flex-direction: column;
        }
        .li-recommend{
            margin: 0;
            padding-bottom: 1.25rem;
            border-bottom: 1px solid #F2F3F7;
        }
        .info-content{
            width: 100%;
            min-height: 1.25rem;
            margin-top: .875rem;
            padding-bottom: 1.5625rem;
            .img{
                width: 3.125rem;
                height: 3.125rem;                
                img{
                    margin-right: 0;
                }
            }
        }
        .mt13{
            margin-top: .3125rem;
        }
        .name{
            width: 15rem;
            margin-left: .3125rem;
            font-size: 1.25rem;
            font-weight: 600;
        }
        .reward{
            font-size: .75rem;
            line-height: .875rem;
        }
        .markdown-body p{
            font-size: .875rem;
            line-height: 1rem;
        }
        .time{
            font-size: .875rem;
            line-height: 1.25rem;
        }
        .recommend{
            width: 100%;
            height: 13.75rem;
            margin-top: 1rem;
        }
        .submit-bg{
            width: 100%;
            padding: .375rem 0 1.125rem;
            position: fixed;
            z-index: 111;
            left: 0;
            bottom: 0;
            background-color: #fff;
            display: flex;
            justify-content: center;
        }
        .submit{
            width: $container-width;
            height: 3.125rem;
            font-size: .875rem;
            border-radius: .625rem;
        }
    }
    .info-top{
        height: auto;
        margin-top: .8125rem;
        overflow: hidden;
        margin-bottom: 0;
    }
    .info-bottom{
        li{
            margin-bottom: 2.1875rem;
        }
    }
}