.User{
    width: 1440px;
    margin: 0 auto;
    margin-bottom: 100px;
    padding-top: 135px;
}
.User-info{
    height: 130px;
    display: flex;
    position: relative;
    .btn{
        min-width: 130px;
        height: 42px;
        padding: 0 25px;
        border-radius: 10px;
        position: absolute;
        right: 0;
        bottom: 0;
        .anticon-edit{
            font-size: 16px;
        }
    }
    .img{
        width: 130px;
        height: 130px;
        margin-right: 30px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .info{
        .name{
            font-size: 32px;
            font-weight: 600;
        }
        .address{
            margin-top: 5px;
            color: #111111;
            font-size: 12px;
            line-height: 17px;
            cursor: pointer;
        }
        .did{
            margin: 10px auto 22px;
            display: flex;
            align-items: center;
            img{
                width: 14px;
                height: 14px;
            }
            .label{
                padding: 0 6px;
                margin: 0 5px;
                line-height: 17px;
                border: 1px solid #DFDFDF;
                border-radius: 3px;
            }
            .flex{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .keyfile{
                margin-left: 14px;
                padding: 2px 6px;
                display: flex;
                align-items: center;
                background-color: rgba($color: #2F3745, $alpha: 0.05);
                border-radius: 3px;
                cursor: pointer;
            }
        }
        .social{
            height: 32px;
            margin-top: 5px;
            display: flex;
            gap: 20px;
            .social-item{
                // padding: 9px 23px 9px 12px;
                // padding: ;
                padding-left: 12px;
                padding-right: 23px;
                
                display: flex;
                align-items: center;
                border-radius: 17px;
                background-color: rgba($color: #5965F6, $alpha: 0.05);
            }
            .icon{
                // width: 20px;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background-color: #00000000;
            }
        }
        .desc{
            max-width: 600px;
            margin-top: 10px;
            color: #9E9E9E;
            line-height: 25px;
            -webkit-line-clamp: 2;
        }
    }
}
.User-list{
    margin-top: 95px;
    ul{
        display: flex;
        li{
            cursor: pointer;
            user-select: none;
        }
    }
    .challenge{
        margin-bottom: 25px;
        li{
            margin-right: 45px;
            color: #999999;
            font-size: 24px;
            line-height: 28px;
        }
        .active{
            color: #000;
            cursor: auto;
            font-weight: 500;
        }
    }
    .status{
        li{
            margin-right: 50px;
            color: #999999;
            font-size: 16px;
            line-height: 19px;
        }
        .active{
            color: #111111;
            font-weight: 500;
            cursor: auto;
            position: relative;
            &::after{
                content: "";
                width: 100%;
                height: 3px;
                position: absolute;
                left: 0;
                bottom: -5px;
                background-color: #8F5A35;
            }
        }
    }
}
.User-content{
    padding-block: 30px 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    .nodata{
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            margin-bottom: 35px;
            color: #C1C1C1;
            font-size: 20px;
            line-height: 28px;
        }
        .nodata-btn{
            height: 44px;
            padding: 10px 56px;
            border-radius: 10px;
            background: #FFFFFF;
            border: 1px solid #000000;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
    .ant-pagination{
        margin-top: 45px;
        .ant-pagination-item{
            background-color: #F2F3F4;
            border-radius: 5px;
            &:hover{
                background-color: #F2F3F4;
                a{
                    color: #000;
                }
            }
        }
        // 选中
        .ant-pagination-item-active{
            border: none;
            background-color: #fff;
            a{
                color: #000;
                font-weight: normal;
            }
        }
    }
}