.Pagination{
    width: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    .box{
        width: 24px;
        height: 24px;
        cursor: pointer;
        color: #9C9C9C;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }
    .active{
        color: #000;
    }
}