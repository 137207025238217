.ModalAddSbt{
    .ant-modal-close{
        width: 33px;
        height: 33px;
        .ant-modal-close-x{
            font-size: 18px;   
            color: #0D0D0D;
        }
    }
    .ant-modal-content{
        padding: 44px 65px 36px !important;
    }
    .title{
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
    }
    .search{
        .inner{
            width: 100%;
            height: 50px;
            display: flex;
            gap: 20px;
            .ant-select{
                padding-left: 20px;
                border-radius: 10px;
                border: 1px solid #D8D8D8;
                display: flex;
                align-items: center;
                .img{
                    width: 26px;
                    height: 26px;
                }
                .ant-select-selector{
                    padding: 0;
                    border: 1px solid #000;
                }
                .label{
                    padding-right: 15px;
                }
                .ant-select-selection-item{
                    padding-right: 35px;
                }
                .ant-select-arrow{
                    right: 20px;
                    color: #000;
                }
            }
            .input{
                width: 100%;
                border: 1px solid #D8D8D8;
                border-radius: 10px;
                position: relative;
                input{
                    height: 100%;
                    padding-left: 45px;
                    font-size: 15px;
                }
                .icon{
                    position: absolute;
                    left: 17px;
                    top: 0px;
                    color: #BABABA;
                    font-size: 14px;
                    line-height: 50px;
                }
            }
        }
    }
    .list-content{
        flex-wrap: wrap;
        display: flex;
        gap: 20px 17px;
    }
    .content{
        margin-top: 50px;
        .box-active, .box:hover{
            .checkbox{
                display: block !important;
            }
        }
        .box{
            width: 166px;
            height: 222px;
            position: relative;
            overflow: hidden;
            border-radius: 5px;
            border: 1px solid #F0F0F0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .checkbox{
                width: 32px;
                height: 29px;
                border: 1px solid #CECECE;
                border-bottom: none;
                border-right: none;
                position: absolute;
                right: 0;
                bottom: 0;
                display: none;
                text-align: center;
                line-height: 29px;
                color: #07A35A;
            }
            .img{
                width: 100%;
                height: 164px;
                overflow: hidden;
            }
            p{
                width: 100%;
                padding: 10px;
                color: #17151C;
                font-size: 12px;
                line-height: 14px;
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
            }
            .badge{
                width: 34px;
                height: 34px;
                border-radius: 50%;
                position: absolute;
                right: 3px;
                top: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 16px;
                background-color: rgba(255,255,255,0.6);
                opacity: 0;
                transition: opacity 0.3s;
                cursor: pointer;
            }
            .show{
                background-color: rgba(0,0,0,0.3);
                color: #fff;
            }
            &:hover .badge, .show{
                opacity: 1;
            }
        }
    }
    .confirm{
        width: 266px;
        height: 50px;
        margin-top: 35px;
        border-radius: 10px;
        border: 1px solid #8F5A35;
        color: #8F5A35;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        left: calc((100% - 266px) / 2);
    }
}

.dropdownMenu{
    font-size: .5625rem;
    .label{
        font-size: .5625rem;
    }
}

.ModalAddSbt-mobile{
    .ant-modal-close{
        .ant-modal-close-x{
            font-size: 1rem;   
        }
    }
    .ant-modal-content{
        padding: 1.875rem .9375rem 1.25rem !important;
    }
    .title{
        margin-bottom: .625rem;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1rem;
    }
    .search{
        .inner{
            width: auto;
            height: auto;
            display: block;
            .ant-select{
                width: 50%;
                margin-bottom: .5rem;
                padding-left: .8125rem;
                border-radius: .4375rem;
                border-width: .0625rem;
                .img{
                    width: 1.125rem;
                    height: 1.125rem;
                }
                .ant-select-selector{
                    border-width: .0625rem;
                }
                .label{
                    padding-right: .625rem;
                    font-size: .75rem;
                }
                .ant-select-selection-item{
                    padding-right: 2.1875rem;
                }
                .ant-select-arrow{
                    right: 1.25rem;
                }
            }
            .input{
                width: 100%;
                height: 2.1875rem;
                border-width: .0625rem;
                border-radius: .4375rem;
                input{
                    padding-left: 2.125rem;
                    font-size: .75rem;
                }
                .icon{
                    left: .875rem;
                    font-size: .625rem;
                    line-height: 2.0625rem;
                }
            }
        }
    }
    .content{
        height: 21.875rem;
        margin-top: .9375rem;
        .list-content{
            gap: .625rem;
        }
        .box{
            width: 6rem;
            height: 8.125rem;
            border-radius: .3125rem;
            border-width: .0625rem;
            .img{
                height: 6.25rem;
            }
            p{
                width: 100%;
                padding: .3125rem;
                font-size: .4375rem;
                line-height: .5rem;
            }
            .badge{
                width: 1.375rem;
                height: 1.375rem;
                border-radius: 50%;
                right: .1875rem;
                top: .1875rem;
                font-size: .75rem;
            }
        }
    }
    .confirm{
        width: 6.5625rem;
        height: 2.1875rem;
        padding: 0;
        margin-top: 1.75rem;
        border-radius: .4375rem;
        border-width: .0625rem;
        font-size: .625rem;
        line-height: 2.1875rem;
        left: calc((100% - 6.5625rem) / 2);
    }
}