.Mobile{
    #Header{
        background-color: rgba(255,255,255,0.5);
        &,.nav-left a{
            color: #000;
        }
        .user{
            border: none;
        }
        .header-content{
            width: 100%;
            height: 3.75rem;
            padding-left: .9375rem;
            padding-right: .9375rem;
        }
        .nav-right{
            width: auto;
        }
        .bg000{
            background-color: #000;
        }
        .nav-left > a{
            display: none;
        }
        .logo{
            width: 6.4375rem;
            height: 1.5rem;
        }
        .custom-btn{
            width: 1.625rem;
            height: 1.625rem;
            padding: 0;
            font-size: 1.375rem;
            border-radius: .4375rem;
            img{
                width: 1.25rem;
                height: 1.25rem;
            }
        }
        .user{
            height: 2rem;
            margin-right: 0;
            padding-right: .125rem;
                img{
                width: 1.75rem;
                height: 1.75rem;
                margin-left: .125rem;
            }
            p{
                margin-left: .625rem;
            }
        }
        .cfff{
            color: #fff;
        }
        .nav-right{
            .mask-box{
                width: 100vh;
                height: calc(100vh - 3.75rem);
                display: none;
                background-color: #fff;
                position: fixed;
                top: 3.75rem;
                left: 0;
                li{
                    height: 4.375rem;
                    padding-left: 2.125rem;
                    padding-right: 2.125rem;
                    line-height: 4.375rem;
                    font-size: 1.25rem;
                    font-weight: 700;
                    border-bottom: .0625rem solid #F7F7F7;
                    color: #3D3D3D;
                    display: flex;
                }
                .toggle{
                    font-weight: 500;
                    .icon{
                        margin-top: .1875rem;
                        font-size: 1.6875rem;
                        line-height: 4.375rem;
                    }
                    p{
                        margin-left: .3125rem;
                        font-size: .875rem;
                    }
                }
                a{
                    color: #3D3D3D;
                }
                button{
                    width: 78vw;
                    height: 3.125rem;
                    border-radius: 1.5625rem;
                    position: absolute;
                    left: calc((100vw - 78vw) / 2);
                    bottom: 15%;
                }
            }
            .mask-box-show{
                display: block;
            }
        }
    }
    .ant-layout-footer{
        padding: 0 !important;
    }
    #Footer{
        .footer-content{
            padding: 1.5625rem 1.8125rem 1.6875rem;
            flex-direction: column;
            align-items: flex-start;
            .left{
                .logo{
                    width: 8.125rem;
                    height: 1.875rem;
                }
                p{
                    margin-top: .75rem;
                    font-size: .875rem;
                    line-height: 1rem;
                }
            }
            .right{
                margin-top: 2.75rem;
                a{
                    color: #B0B0B0;
                    margin-right: 1.25rem;
                }
            }
        }
        .footer-record{
            margin: .75rem auto;
            font-size: .75rem;
            line-height: .875rem;
        }
    }
    .markdown-body{
        font-size: 1rem;
    }

    // 页面顶部背景
    .custom-bg-round{
        width: calc(87.5rem / 120rem * 100%);
        height: 25rem;
        background: #B27347;
        filter: blur(4.375rem);
        top: -18.75rem;
        left: calc((100% - (87.5rem / 120rem * 100%)) / 2);
    }

    .notfound{
        img{
            width: 22.5rem;
            height: 9.875rem;
            margin-bottom: 4.0625rem;
        }
        .notfound-content{
            margin-bottom: 2.1875rem;
            font-size: 1.125rem;
            line-height: 1.3125rem;
        }
        .btn{
            height: 3.125rem;
            padding: 0 2.1875rem;
            border-radius: .5rem;
            font-size: 1rem;
        }
    }
}