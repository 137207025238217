$container-width: 21.875rem;
.Mobile{
    .CustomCompleted{
        .completed-content{
            width: $container-width;
            margin-top: 4.6875rem;
            margin-bottom: 4.6875rem;
        }
        .content-info{
            flex-direction: column;
        }
        .desc{
            .title{
                font-size: 1.25rem;
                line-height: 1.8125rem;
                text-align: left;
            }
        }
        .score{
            margin-top: 3.75rem;
            .circle {
                position: relative;
                .ant-progress-text{
                    font-size: 2.3125rem;
                    line-height: 3.25rem;
                    font-weight: 500;
                }
                .text{
                    font-size: .75rem;
                    line-height: 1.0625rem;
                    bottom: -1rem;
                }
                .text-unpass{
                    font-size: 1.5625rem;
                    font-weight: 500;
                }
            }
            .info{
                margin-left: 2.625rem;
                .network{
                    max-width: calc($container-width - 1.875rem - 8.75rem);
                    margin-bottom: 1.25rem;
                    font-size: 1.125rem;
                    line-height: 1.625rem;
                    -webkit-line-clamp: 2;
                }
                .pass{
                    font-size: .625rem;
                    line-height: 1.25rem;
                    color: #000;
                }
            }
            
        }
        .annotation{
            width: 100%;
            margin-left: 0;
        }
        .mr{
            width: 100%;
            margin: 2.75rem auto 0;
            display: flex;
            justify-content: space-between;
            .btn{
                width: 48.5%;
                height: 1.9375rem;
                margin: 0;
                // padding: 0 2.625rem;
                padding: 0;
                border-radius: .3125rem;
                font-size: .75rem;
                font-weight: 400;
                line-height: 1.0625rem;
            }
        }
        .viewer{
            margin-top: 3.75rem;
            .viewer-head{
                font-size: 1.25rem;
                line-height: 1.625rem;
            }
            .ant-divider{
                // display: none;
                border-width: .0625rem;
                position: relative;
                &::after{
                    content: "";
                    width: 3.5rem;
                    height: .125rem;
                    background-color: #262626;
                    position: absolute;
                    left: 0;
                    top: -0.1875rem;
                }
            }
            .viewer-content{
                margin-top: .5rem;
                min-height: 18.75rem;
                padding: 1.25rem;
                border: none;
                background-color: #FAFAFA;
            }
        }
        .content-step{
            margin-top: 3.75rem;
            flex-direction: column;
            position: relative;
            h5{
                margin: 0;
                font-size: 1.125rem;
                line-height: 1.625rem;
                font-weight: 500;
                position: absolute;
                top: 0;
                left: 0;
            }
            .nft{
                width: 9.375rem;
                margin: 0 auto;
                img{
                    height: 9.375rem;
                }
                .img{
                    margin-top: 3.75rem;
                    width: 9.375rem;
                    height: 9.375rem;
                    border-radius: .6875rem;
                    .icon{
                        width: 1.0625rem;
                        height: 1.0625rem;
                        right: .3125rem;
                        top: .3125rem;
                    }
                    .img-mask{
                        height: 2.125rem;
                        padding: 0 .625rem;
                        .newline-omitted{
                            font-size: .5625rem;
                        }
                    }
                }
            }
            .step{
                width: 100%;
                // 自定义step type
                .tips{
                    top: 7rem;
                    left: 1.5625rem;
                    font-size: .625rem;
                }
                .step-detail{
                    margin-top: 3.125rem;
                    .ant-steps-item-icon{
                        width: .8125rem;
                        height: .8125rem;
                        margin-top: 0.75rem;
                        margin-right: .75rem;
                    }
                    .custom-dot{
                        width: .25rem;
                        left: .25rem;
                        bottom: .3125rem;
                        .dot{
                            width: .25rem;
                            height: .25rem;
                            margin-bottom: .75rem;
                        }
                    }
                    .ant-steps-item-description{
                        padding-bottom: 2.375rem;
                    }
                }
            }
        }
    }
    
    .step-box{
        width: 100%;
        height: 2.5rem;
        border-radius: .5rem;
    
        font-size: .75rem;
        font-weight: 500;
        line-height: 1.0625rem;
        .mw-140{
            min-width: 90px;
        }
    }
    // tips
    .position{
       position: relative;
       .tips{
            position: absolute;
            right: .875rem;
            bottom: .1875rem;
            color: #999999;
            font-size: 2.125rem;
       }
       
    }
    .innerHref{
        width: 17.25rem;
        margin-left: 1.5625rem;
        padding-left: .1875rem;
        padding-right: 1.125rem;
        input{
            width: 12.5rem;
            height: 2rem;
            border-radius: .5rem;
            font-size: .75rem;
            line-height: 1.0625rem;
        }
        button{
            width: auto;
            padding: 0;
            height: 2.125rem;
            font-size: .75rem;
            line-height: 1.4375rem;
        }
    }
    
    .CustomBox{
        padding-left: .8125rem;
        padding-right: .4375rem;
        justify-content: space-between;
        button{
            height: 1.875rem;
            padding-left: .625rem;
            padding-right: .625rem;
            border-radius: .5rem;
            font-size: .75rem;
            line-height: 1.0625rem;
            gap: 0.7rem;
            .icon{
                font-size: 1rem;
            }
        }
        .discord-box{
            margin-right: .8125rem;
        }
        p:last-child{
            font-size: .75rem;
        }
    }
    .flex{
        padding-left: 0;
        padding-right: 0;
        button{
            height: 2.5rem;
        }
    }
    .pl70{
        padding-left: 2.5rem;
        .icon{
            width: 1.25rem;
            margin-top: .125rem;
            left: .75rem;
        }
    }
    
    .CustomDiscord{
        padding-left: 2.5625rem;
        padding-right: .875rem;
        justify-content: space-between;
    }
    
    .CustomCliam{
        padding: 0;
        background-color: #ffffff00;
        border: none;
        .ant-ribbon-text{
            font-size: .375rem;
        }
        .box{
            width: 8.9375rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff00;
            border-radius: 2rem;
            font-size: .875rem;
            font-weight: 500;
            line-height: 1.25rem;
            p{
                width: 3.5625rem;
            }
            .share{
                padding-left: .8125rem;
                padding-right: 1.375rem;
            }
            button{
                height: 2.5rem;
                border-radius: .5rem;
                font-size: .75rem;
            }
        }
        .ant-ribbon{
            height: .875rem;
            border-radius: 0rem !important;
            top: 0;
            font-size: .5rem;
            line-height: .875rem;
        }
        .ant-ribbon-placement-end{
            right: -0.4375rem;
        }
        .ant-ribbon-corner{
            right: 0;
        }
    }
    .isClaim{
        justify-content: center;
    }
}
.tooltip-m{
    max-width: 19.375rem !important;
}
.tip-content{
    padding-left: .625rem;
    padding-right: .625rem;
}