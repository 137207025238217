.Mobile{
    .Explore {
        width: 21.875rem;
        min-height: 100vh;
        padding-top: 5.625rem;
        .back{
          position: absolute;
          display: flex;
          align-items: center;
          top: 4.3375rem;
          font-size: .75rem;
          cursor: pointer;
          .anticon{
            margin-right: .3125rem;
            font-size: 1rem;
          }
        }
        // title
        h3 {
          margin-bottom: 1.25rem;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.5625rem;
        }
        // depass
        .depass {
          margin-top: 3.9375rem;
          h4 {
            font-size: 1.25rem;
            font-weight: 800;
            color: #3d3d3d;
          }
        }
        // challenges
        .challenges {
          padding: 0;
          padding-bottom: 9.375rem;
          gap: .625rem;
        }
      }
}