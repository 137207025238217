.message-share{
    width: 450px !important;
    height: 214px;
    margin: 100px auto 0;
    padding: 0;
    .ant-message-notice-content{
        // height: 200px;
        padding: 20px 30px;
        background-color: #D8D8D8;
        border-radius: 20px;

        font-size: 24px;
        font-weight: 500;
        line-height: 52px;

        h4{
            font-size: 36px;
        }
    }
}
.mobile-message-share{
    width: 22.5rem !important;
    margin: 6.25rem auto 0;
    padding: 0 !important;
    .anticon{
        font-size: 1.5rem !important;
    }
    .ant-message-notice-content{
        padding: 1.6875rem !important;
        padding-bottom: 2.3125rem !important;
        background-color: #f4ffec !important;
        border-radius: .6875rem !important;

        font-size: .875rem;
        line-height: 1.25rem;
        color: #333333;

        h4{
            margin-bottom: 1rem;
            color: #000000;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.625rem;
        }
        h1{
            margin: 0;
        }
    }
    .ant-message-custom-content{
        display: flex;
        align-items: flex-start;
        text-align: left;
    }
}