.ModalImgCard{
    .ant-modal-content{
        padding: 0 !important;
        background-color: #FBF8F6 !important;
        border-radius: 20px !important;
    }
    .imgCard-content{
        padding: 30px;
        .content{
            padding: 22px;
            background-color: #fff;
        }
        img{
            width: 360px;
            height: 360px;
        }
    }
    .imgCard-btns{
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-end-end-radius: 20px;
        border-end-start-radius: 20px;
        button{
            width: 52px;
            height: 52px;
            padding: 0;
            border: none;
            background-color: #ffffff00;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.ModalImgCard-mobile{
    .imgCard-content{
        img{
            width: 100%;
            height: 100%;
        }
    }
}