.ChallengeItem{
    width: 700px;
    height: 250px;
    padding: 26px;
    background: #ffffff;
    border-radius: 10px;
    border: 2px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s cubic-bezier(0.47, 0, 0.745, 0.715), border 0.3s linear 0.1s;
    position: relative;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05);
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      transform: translateY(-10px);
      .right-sbt .opensea, .edit{
        opacity: 1;
      }
    }
    .edit{
      width: 38px;
      height: 38px;
      border-radius: 10px;
      right: 15px;
      top: 15px;
      position: absolute;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
      color: #121929;
      font-size: 14px;
      // opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s, background-color 0.2s, color 0.2s;
      &:hover{
        color: #777777FF;
        background-color: #0000000D;
      }
    }
    .item-claimed, .item-claimable{
      width: 90px;
      height: 36px;
      color: #fff;
      position: absolute;
      left: -2px;
      top: -2px;
      text-align: center;
      line-height: 36px;
      font-size: 13px;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      z-index: 1;
    }
    .item-claimable{
      background-color: #43B472;
    }
    .item-claimed{
      background-color: #fff;
      color: #43B472;
      border: 1px solid #43B472;
    }
    .left-info {
      width: 420px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        margin-bottom: 18px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        -webkit-line-clamp: 2;
      }
      .desc{
        color: #999;
        font-size: 16px;
        line-height: 20px;
        -webkit-line-clamp: 3;
      }
      .collection-author{
        display: flex;
        align-items: center;
        .img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          background: linear-gradient(180deg, #504027 0%, #1F1B18 100%);
        }
        p{
          margin-left: 10px;
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
        }
      }
      .sbt-detail{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .flex{
          color: #404040;
          display: flex;
          align-items: center;
          gap: 12px;
        }
        img{
          width: 21px;
          height: 21px;
          margin-right: 5px;
        }
        .time{
          color: #404040;
          line-height: 24px;
          display: flex;
          align-items: center;
          .anticon{
            margin-right: 12px;
            font-size: 18px;
            color: rgba(0,0,0,0.5);
          }
        }
      }
    }
    .right-sbt {
      width: 195px;
      height: 195px;
      border-radius: 7px;
      overflow: hidden;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
      }
      .img-mask{
        width: 100%;
        height: 45px;
        padding: 0 11px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        position: absolute;
        overflow: hidden;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
        .newline-omitted{
          -webkit-line-clamp: 2;
        }
      }
      .opensea{
        width: 32px;
        height: 32px;
        opacity: 0;
        transition: opacity 0.2s;
      }
      .show{
        opacity: 1;
      }
      .sbt-bottom{
        width: 100%;
        height: 30px;
        padding: 5px 14px 5px 9px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;

        background-color: #00000080;
        div{
          display: flex;
          align-items: center;
          p{
            margin-left: 5px;
            color: #fff;
          }
        }
        img{
          width: 20px;
          height: 20px;
        }
      }
    }
}