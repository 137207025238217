.Publish{
    width: 1200px;
    padding-block: 105px;
    margin: 0 auto;
    .ant-form-item-control-input-content{
        position: relative;
    }
    .challenge-title{
        width: calc(195px - 2px);
        height: calc(195px - 150px);
        padding: 0 11px;
        position: absolute;
        top: 149px;
        left: 1px;
        background: linear-gradient(356deg, #000000 0%, rgba(0,0,0,0) 100%);
        display: flex;
        align-items: center;
        color: #fff;
        border-end-end-radius: 16px;
        border-end-start-radius: 16px;
        .img-desc{
            font-size: 12px;
            font-weight: 600;
            -webkit-line-clamp: 2;
        }
    }
    .custom-upload{
        .upload-btn{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ant-upload-list-item{
            padding: 0 !important;
            border-radius: 16px !important;
            overflow: hidden;
            border: 1px dashed #DEDEDE !important;
            &::before{
                width: 100% !important;
                height: 100% !important;
            }
            // 隐藏预览
            .ant-upload-list-item-actions{
                a{
                    display: none;
                }
            }
        }
        .ant-upload-list-item-error{
            border: 1px solid #ff4d4f !important;
            .ant-upload-list-item-thumbnail{
                height: auto !important;
            }
            .ant-upload-list-item-name{
                width: 100% !important;
                bottom: 53px !important;
            }
        }
        .ant-upload-list-item-container, .ant-upload-select{
            width: 195px !important;
            height: 195px !important;
        }
        .ant-upload-list-picture-card{
            display: flex;
        }
        .upload-icon{
            font-size: 30px;
        }
        .text-title{
            font-weight: 500;
        }
        .text-normal{
            font-size: 12px;
            color: #a0aec0;
        }
    }
    .Editor-hide {
        .ant-col{
            max-width: 100%;
            .tip{
                margin-left: 14px;
                font-size: 12px;
                color: #999999;
                line-height: 17px;
            }
        }
        .bytemd-toolbar-right{
            display: none;
        }
        .bytemd-toolbar-left{
            .bytemd-tippy{
                &:nth-child(6), &:nth-child(7), &:nth-child(8){
                    display: none;
                }
            }
        }
    }
    h3{
        margin-bottom: 42px;
        font-size: 30px;
        font-weight: bold;
        color: #000000;
        line-height: 35px;
    }
    .inner{
        padding: 20px;
    }
    .questions{
        .quest-head{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left, button{
                color: #94A5B7;
            }
            .left{
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                span{
                    color: #F84646;
                }
            }
            button{
                font-size: 12px;
            }
        }
    }
    .add-btns{
        height: 50px;
        display: flex;
        align-items: center;
    }
    .challenge-info{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        .ant-form-item, .form-item{
            width: 50%;
            .ant-row{
                flex-flow: nowrap;
            }
            .ant-form-item-row{
                flex-direction: row;
            }
            .ant-form-item-control{
                width: 50%;
                & > .ant-form-item-control-input{
                    width: 200px;
                }
            }
        }
        .form-item{
            display: flex;    
            margin-bottom: 24px;
            .title{
                width: 120px;
            }
        }
    }
    .Publish-btns{
        width: 100%;
        height: 93px;
        margin-top: 75px;
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        button{
            min-width: 150px;
            height: 42px;
            margin-left: 22px;
            border-radius: 5px;
        }
    }


    // Form Item questions
    .questions{
        position: relative;
        .quest-message{
            position: absolute;
            left: 15px;
            bottom: 60px;
        }
    }
}