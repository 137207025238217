.ModalAnswers{
    .ant-modal-content{
        min-height: 725px;
        padding-inline: 26px !important;
        border-radius: 20px !important;
        // text-align: center;
        position: relative !important;
    }
    .ant-modal-close{
        width: 33px;
        height: 33px;
    }
    h5{
        margin: 24px 20px 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
    }
    .tips{
        margin-left: 20px;
        display: flex;
        gap: 34px;
        .tip{
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: 16px;
        }
        .round{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .point{
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
    }
    // 正确
    .true{
        background-color: rgba($color: #39B377, $alpha: 0.1);
        color: #39B377;
        .point{
            background-color: #39B377;
        }
    }
    // 错误
    .false{
        background-color: rgba($color: #E23F41, $alpha: 0.1);
        color: #E23F41;
        .point{
            background-color: #E23F41;
        }
    }
    // 已答
    .answered{
        background-color: rgba($color: #007DFA, $alpha: 0.1);
        color: #007DFA;
        .point{
            background-color: #007DFA;
        }
    }
    // 未答
    .unanswer{
        background-color: rgba($color: #777777, $alpha: 0.1);
        color: #777777;
        .point{
            background-color: #777777;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
    }
    .success{
        color: #43B472;
        background-color: #43B4721A;
        transition: background-color 0.2s;
        &:hover{
            background-color: #43B47233;
        }
    }
    .normal{
        color: #777777;
        background-color: #0000000D;
        transition: background-color 0.2s;
        &:hover{
            background-color: #00000026;
        }
    }
    .error{
        color: #DE4040;
        background-color: #DE40401A;
        transition: background-color 0.2s;
        &:hover{
            background-color: #DE404033;
        }
    }
    .answered{

    }

    .fc-success{
        color: #43B472;
    }
    .fc-normal{
        color: #9E9E9E;
    }
    .fc-error{
        color: #FF0000;
    }
    // .tips{
    //     .point{
    //         width: 50px;
    //         height: 50px;
    //         margin-right: 15px;
    //         border-radius: 50%;
    //     }
    //     li{
    //         margin-right: 72px;
    //         display: flex;
    //         align-items: center;
    //         font-size: 18px;
    //         font-weight: 500;
    //         line-height: 21px;
    //     }
    // }
    .box{
        height: 517px;
        margin-top: 15px;
        background-color: #F9F9F9;
        border-radius: 20px;
        overflow-x: hidden;
    }
    .answers{
        width: 935px;
        padding: 50px 70px;
        gap: 35px 32px;
        .point{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
        }
    }
    .submit{
        width: 330px;
        height: 60px;
        position: absolute;
        left: calc((100% - 330px) / 2);
        bottom: 23px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        border: none;
        background-color: #8F5A35;
    }
}
.ModalAnswers-mobile{
    .ant-modal-content{
        min-height: 30rem;
        padding: 1.875rem .9375rem 0 .9375rem !important;
        border-radius: .8125rem !important;
    }
    .ant-modal-close{
        width: .75rem;
        height: .75rem;
    }
    h5{
        margin: 0 .625rem 0;
        font-size: .875rem;
        line-height: 1rem;
    }
    .box{
        height: 21.25rem;
        margin-top: 1.125rem;
        border-radius: .8125rem;
    }
    .answers{
        width: 100%;
        padding: 1.875rem 2.125rem;
        gap: 1.5rem 1.375rem;
        .point{
            width: 2.0625rem;
            height: 2.0625rem;
            font-size: .75rem;
            line-height: 2.0625rem;
        }
    }
    .submit{
        width: 13.5625rem;
        height: 2.5rem;
        left: calc((100% - 13.5625rem) / 2);
        bottom: .9375rem;
        font-size: .75rem;
    }
}