.Mobile .Search{
    padding-top: 13.4375rem;
    padding-bottom: 11.875rem;
    overflow: hidden;
    .round1{
        width: 11.1875rem;
        height: 11.1875rem;
        top: -2.3125rem;
        left: calc((100% - 11.1875rem) / 2 - 5.9375rem);
    }
    .round2{
        width: 17.125rem;
        height: 17.125rem;
        top: 15rem;
        right: calc((100% - 17.125rem) / 2 - 8.125rem);
    }
    .Search-content{
        .title{
            font-size: 3.75rem;
            line-height: 4.375rem;
            font-weight: 900;
        }
        .fs-zh{
            margin-top: 60px;
            font-size: 38px;
            text-align: center;
        }
        .color-primary{
            margin-top: -0.7813rem;
        }
        .subtitle{
            font-size: .8125rem;
            line-height: 1rem;
        }
        .inner{
            width: 19.875rem;
            height: 2.875rem;
            padding-left: 1.25rem;
            padding-right: .1875rem;
            margin: 1.875rem auto 0;
            border-radius: 2.875rem;
            .icon{
                margin-right: .75rem;
                font-size: 1.0625rem;
            }
            input{
                width: calc(100% - 5.875rem - 1.8125rem);
                font-size: .75rem;
                line-height: .875rem;
            }
            button{
                width: 5.875rem;
                height: 2.5rem;
                font-size: .75rem;
                border-radius: 2.5rem;
            }
        }
        .example{
            width: 19.875rem;
            padding-left: 1.4375rem;
            margin: .9375rem auto 0;
            display: flex;
            li{
                padding: .125rem .625rem;
                margin-right: 1.0625rem;
                font-size: .75rem;
                line-height: .875rem;
                border-radius: 1.25rem;
            }
        }
    }
}