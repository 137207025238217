.Mobile{
    .UserEdit{
        width: 21.875rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .UserEdit-inner{
        height: auto;
        min-height: 34.375rem;
        padding-top: .625rem;
        .title{
            height: 4.25rem;
            font-size: 1.25rem;
        }
        .content{
            padding: .625rem .9375rem 2.6875rem;
            .avatar{
                .btn{
                    padding-left: 1.625rem;
                    padding-right: 1.625rem;
                }
            }
            .inner{
                margin-top: 1.625rem;
                .label{
                    margin-bottom: .75rem;
                }
                .list{
                    .item{
                        padding: 1rem;
                        .item-label{
                            gap: .625rem;
                            font-size: .6875rem;
                            svg{
                                font-size: 1.25rem;
                            }
                            .icon-copy svg{
                                margin-left: .3125rem;
                                font-size: .625rem;
                            }
                        }
                        button{
                            min-width: 6.875rem;
                            height: 1.875rem;
                            font-size: .625rem;
                        }
                    }
                }
                .input{
                    padding-left: 1.4375rem;
                    padding-right: 1.4375rem;
                }
                input{
                    font-size: .75rem;
                    line-height: 1.125rem;
                }
                textarea{
                    padding: .875rem 1.25rem;
                    border-radius: .9375rem;
                    font-size: .75rem;
                }
            }
        }
        .UserEdit-btns{
            top: 1rem;
            .btn{
                height: 2rem;
                font-size: .75rem;
            }
        }
    }
}