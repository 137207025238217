.main {
    // width: 1920px;
    background-color: #000;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .global-prompt{
      width: 100%;
      height: 50px;
      background-color: #FFD469;
      font-weight: 500;
      position: absolute;
      top: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        cursor: pointer;
      }
      a{
          color: #000;
          text-decoration: underline;
      }
    }
    .main-center {
      min-height: 1025px;
      background: #fff;
      padding-top: 220px;
      .main-header{
        position: relative;
        .line{
          height: 37px;
          position: absolute;
          border-radius: 42px;
          z-index: 1;
          background: rgba(185,148,100,0.5);
        }
        .zh-textline1{
          width: 144px;
          left: calc((100% - 144px) / 2 - 237px);
          bottom: 0;
        }
        .zh-textline2{
          width: 280px;
          right: calc((100% - 280px) / 2 - 190px);
          bottom: 0;
        }
        .en-textline1{
          width: 257px;
          left: calc((100% - 257px) / 2 - 107px);
          top: 105px;
        }
        .en-textline2{
          width: 320px;
          right: calc((100% - 320px) / 2 - 292px);
          bottom: 13px;
        }
      }
      // describe
      .describe {
        max-width: 964px;
        margin: 0 auto;
        img{
          width: 100%;
        }
      }
      // text
      .text {
        margin-top: 15px;
        text-align: center;
        font-size: 28px;
        letter-spacing: 1px;
        color: #777777;
        line-height: 33px;
      }
      
      .social{
        width: 193px;
        height: 66px;
        margin: 65px auto 0;
        display: flex;
        justify-content: space-between;
        .social-item{
          width: 66px;
          height: 66px;
          border-radius: 15px;
          background-color: #eee8df;
          font-size: 34px;
          color: #B99464;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .listImg{
        margin: 210px auto 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        .type{
          height: 100px;
          cursor: pointer;
          img{
            height: 100%;
          }
        }
        .arrow{
          width: 26px;
          margin: 0 65px;
          img{
            width: 100%;
          }
        }
      }

      .vision{
        width: 100%;
        height: 600px;
        padding-top: 210px;
        background-color: #C19E6D;
        text-align: center;
        color: #FFFFFF;
        h3{
          margin-bottom: 42px;
          font-size: 68px;
          font-weight: bold;
          line-height: 80px;
        }
        p{
          font-size: 26px;
          line-height: 40px;
        }
        span{
          padding: 0 5px;
          font-size: 30px;
          font-weight: bold;
          color: #B99464;
          display: inline-block;
          border-radius: 6px;
          background-color: #fff;
        }
      }

      .intro{
        position: relative;
        overflow: hidden;
        .intro-content{
          width: 1440px;
          margin: 0 auto;
          background-color: #fff;
          h2{
            font-size: 68px;
            font-weight: bold;
            color: #0A0D10;
            line-height: 80px;
            letter-spacing: 1px;

          }
        }
        .ai{
          margin-bottom: 253px;
          padding-top: 220px;
          display: flex;
          justify-content: space-between;
          .content-r{
            width: 600px;
            height: 500px;
            margin-top: 34px;
          }
          h2{
            margin-bottom: 60px;
          }
          h3{
            margin-bottom: 17px;
            font-size: 26px;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
          }
          p{
            max-width: 640px;
            font-size: 22px;
            color: #7585A2;
            line-height: 26px;

          }
          li{
            margin-bottom: 85px;
          }
        }
        .sbt{
          margin-bottom: 200px;
          display: flex;
          justify-content: space-between;
          .content-l{
            width: 600px;
            height: 600px;
          }
          .content-r{
            padding-top: 108px;
          }
          h2{
            width: 850px;
            margin: 0;
            text-align: right;
          }
          ul{
            margin-top: 60px;
          }
          li{
            margin-bottom: 7px;
            text-align: right;
            font-size: 26px;
            font-weight: bold;
            color: #383B46;
            line-height: 60px;
          }
        }
        .linear-bg{
          width: 250px;
          height: 270px;
          position: absolute;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom right, #fff, #fff, #B27347, #B27347, #B27347);
          opacity: 0.4;
          filter: blur(45px);
        }
      }

      // 合作伙伴
      .partner{
        width: 100%;
        height: 960px;
        padding: 132px 12.55% 0;
        background-color: #C19E6D;
        color: #fff;
        .partner-content{
          // 1835px
          max-width: 1440px;
          margin: 0 auto;
        }
        p{
          margin-bottom: 86px;
          font-size: 50px;
          font-weight: bold;
          line-height: 59px;
        }
        ul{
          width: 100%;
          max-width: calc(6 * 233px + 7px);
          padding: 1px;
          margin: 0 auto;
          border-spacing: 1px;
          display: flex;
          justify-content: center;
        }
        li{
          width: 233px;
          height: 233px;
          background-color: #C19E6D;
          outline: 1px solid #fff;
          img{
            width: 200px;
            height: 62px;
          }
        }
      }

      // 贡献者
      .contributor{
        width: 100%;
        min-height: 723px;
        margin: 0 auto;
        padding: 145px 12.55%;
        text-align: center;
        font-size: 50px;
        line-height: 59px;
        .ofh{
          // max-height: calc(150px + 88px + 58px + 35px);
          // overflow: hidden;
          transition: max-height 0.2s;
        }
        .contributor-label{
          max-width: 1440px;
          margin: 0 auto;
          text-align: left;
          font-size: 50px;
          font-weight: bold;
        }
        .contributor-content{
          max-width: 1440px;
          margin: 88px auto 0;
          display: flex;
          flex-wrap: wrap;
          gap: 55px 34px;
          .contributor-item{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 6px solid #8F5A35;
            overflow: hidden;
          }
          .username{
            width: 130px;
            height: 58px;
            margin: 35px auto 0;
            font-size: 25px;
            color: #8F5A35;
            font-weight: 400;
            line-height: 29px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            /* 作为弹性伸缩盒子模型显示 */
            display: -webkit-box;
            /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
            -webkit-box-orient: vertical;
            /* 显示的行 */
            -webkit-line-clamp: 2;
          }
        }
        .btn-drop{
          width: 66px;
          height: 66px;
          margin: 65px auto 0;
          border-radius: 15px;
          background-color: #eee8df;
          font-size: 34px;
          color: #B99464;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }