.modal-keyfile{
    width: 404px !important;
    padding-bottom: 0 !important;
    .ant-modal-content{
        padding: 22px !important;
        border-radius: 18px !important;

        .ant-modal-header{
            margin-bottom: 0;
        }

        .ant-modal-body{
            .line{
                height: 1px;
                margin: 14px 0;
                background-color: #F0F0F0;
            }
            .did{
                height: 35px;
                padding: 7px 13px;
                background-color: #FBF8F6;

                display: flex;
                align-items: center;
                justify-content: space-between;
                .title{
                    color: #1E1E1F;
                }
                .desc{
                    font-weight: 500;
                }
            }
            .keyfile{
                margin: 14px 0 30px;
                padding: 20px 28px;
                border-radius: 5px;
                border: 1px solid #DDDDDD;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                p{
                    text-align: center;
                }
                .keyfile-title{
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #1D1D1D;
                }
                button{
                    color: #8F5A35;
                    border-color: #DDDDDD;
                    box-shadow: none;
                }
            }
        }

        .ant-modal-footer{
            display: flex;
            justify-content: space-between;
            button{
                width: 47.22%;
                height: 38px;
                box-shadow: none;
                font-weight: 500;
            }
            .ant-btn-default{
                border-color: #C5C5C5;
            }
            .ant-btn-primary{
                background-color: #8F5A35;
            }
        }
    }
}

.modal-keys{
    width: 404px !important;
    padding-bottom: 0 !important;
    .ant-modal-content{
        padding: 22px !important;
        border-radius: 18px !important;

        .ant-modal-header{
            margin-bottom: 0;
            .ant-modal-title{
                text-align: center;
            }
        }

        .ant-modal-body{
            .line{
                height: 1px;
                margin: 14px 0;
                background-color: #F0F0F0;
            }
            .did{
                .title{
                    color: #1E1E1F;
                    font-size: 12px;
                    text-align: center;
                }
            }
            .keyfile{
                margin: 14px 0 30px;
                padding: 14px 19px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border: 1px solid #E9E9E9;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .keyfile-title{
                    line-height: 20px;
                    font-size: 14px;
                    color: #5C5B5A;
                }
                .keyfile-desc{
                    font-weight: 500;
                }
                button{
                    color: #8F5A35;
                    border-color: #DDDDDD;
                    box-shadow: none;
                }
            }
        }

        .ant-modal-footer{
            display: flex;
            justify-content: space-between;
            button{
                width: 47.22%;
                height: 38px;
                box-shadow: none;
                font-weight: 500;
            }
            .ant-btn-default{
                border-color: #C5C5C5;
            }
            .ant-btn-primary{
                background-color: #8F5A35;
            }
        }
    }
}