.preview-head{
    width: 100%;
    padding-left: 150px;
    padding-right: 130px;
    text-align: center;
    position: absolute;
    top: 32px;
    left: 0;
    color: #111111;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    z-index: 1;
    .btn-exit{
        border: none;
        box-shadow: none;
        color: #111111;
        font-size: 18px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 130px;
        .icon{
            font-size: 16px;
        }
    }
}
.Challenge{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 140px;
    position: relative;
    background-color: #F9F9F9;

    .quest-title{
        width: 100%;
        padding-top: 82px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        .line{
            border-top: 1px solid #F9F9F9;
        }
        .title{
            width: 100%;
            height: 50px;
            margin: 0 auto;
            padding-left: 150px;
            padding-right: 130px;
            display: flex;
            align-items: center;
            color: #111111;
            font-size: 18px;

            span{
                font-size: 17px;
            }
            a{
                color: #111111;
            }
            p{
                max-width: 900px;
                margin-left: 20px;
                line-height: 21px;
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
            }
        }
    }
    .content{
        width: calc(100vw - 150px - 130px);
        height: calc(100vh - 140px - 87px - 7px);
        margin: 0 130px 0 150px;
        padding-bottom: 50px;
        // box-sizing: content-box;
        // box-sizing: border-box !important;
        background-color: #fff;
        position: relative;
        overflow-y: auto;
        .challenge-title{
            // width: 50%;
            padding: 30px 50px 10px;
            margin-bottom: 15px;
            color: #666666;
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
            border-bottom: 1px solid #F4F4F4;
            display: flex;
            align-items: center;
            strong{
                max-width: 80%;
                margin-left: 17px;
                font-size: 20px;
                font-weight: 500;
                color: #262626;
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
                display: inline-block;
            }
            .score{
                color: #43B472;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
            }
        }
        .inner-title{
            font-size: 16px;
            color: #262626;
            line-height: 19px;
        }
    }
    .progress{
        width: 100%;
        margin: 0 auto;
        line-height: 0;
        position: fixed;
        bottom: 82px;
        left: 0;
        .ant-progress{
            margin: 0;
            line-height: 0;
        }
        .ant-progress-bg{
            height: 5px !important;
            background-color: #8F5A35;
        }
        .ant-progress-inner{
            background-color: #F9F9F9;
        }
    }
}
.isCover{
    width: 410px !important;
    // min-width: 364px;
    .ant-modal-content{
        padding: 60px 55px 40px !important;
        position: relative;
        border-radius: 0 !important;
        .ant-modal-confirm-content{
            text-align: center;
            font-size: 18px;
            line-height: 25px;
            .anticon-close{
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
            }
            .confirm-title{
                margin-bottom: 30px;
                color: #0D0D0D;
            }
            .confirm-content{
                color: #999999;

            }
        }
        .ant-modal-confirm-btns{
            margin-top: 70px;
            display: flex;
            justify-content: center;
            gap: 15px;
            button{
                width: 120px;
                height: 40px;
                border-radius: 10px;
            }
            .ant-btn-primary{
                margin: 0;
                background-color: #8F5A35;
                &:hover{
                    background-color: #a4704b;
                }
            }
            .ant-btn-default{
                color: #999999;
                border-color: #999999;
                &:hover{
                    color: #d0d0d0;
                    border-color: #d0d0d0;
                }
            }
        }
    }
}