.ModalUpload{
    .ant-modal-close{
        top: 17px;
        height: 19px;
    }
    .ant-modal-content{
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .ant-menu-title-content{
        font-size: 12px;
    }
    .img-list{
        padding: 15px 13px;
        display: flex;
        gap: 10px;
        img{
            width: 130px;
            height: 130px;
            cursor: pointer;
        }
    }
    .img-upload{
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        button{
            width: 100%;
            margin-bottom: 15px;
        }
        .title{
            font-weight: 500;
        }
        .tips{
            font-size: 12px;
            color: #A3B2C2;
            line-height: 16px;
        }
    }
}